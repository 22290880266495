:root {
	--slate-grey: #1c2b3c;
	--orange: #ff8000;
	--8: #e3e5e7;
	--bright-red: red;
	--4: #f6f7f7;
	--untitled-ui-gray400: #98a2b3;
	--30: #babfc4;
	--untitled-ui-gray600: #475467;
	--60: #77808a;
	--untitled-ui-gray500: #667085;
	--untitled-ui-gray700: #344054;
	--untitled-ui-gray300: #d0d5dd;
	--untitled-ui-white: white;
	--untitled-ui-gray900: #101828;
	--green: #006532;
	--burgundy: #900;
	--purple: rebeccapurple;
	--yellow: #ffb900;
	--blue: #004d99;
	--untitled-ui-gray200: #eaecf0;
	--untitled-ui-success50: #ecfdf3;
	--untitled-ui-success700: #027a48;
	--untitled-ui-primary600: #7f56d9;
	--untitled-ui-primary700: #6941c6;
	--untitled-ui-primary100: #f4ebff;
	--untitled-ui-primary300: #d6bbfb;
}

.mt-0 {
	margin-top: 0px !important;
}

.top-filtering-div.ribbons-top-filtering {
	display: none;
}

.w-layout-grid {
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.w-checkbox {
	margin-bottom: 5px;
	padding-left: 20px;
	display: block;
}

.w-checkbox:before {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox:after {
	content: " ";
	clear: both;
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox-input {
	float: left;
	margin: 4px 0 0 -20px;
	line-height: normal;
}

.w-checkbox-input--inputType-custom {
	width: 12px;
	height: 12px;
	border: 1px solid #ccc;
	border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
	background-color: #3898ec;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
	box-shadow: 0 0 3px 1px #3898ec;
}

.w-button {
	background-color: transparent;
	padding: 9px 15px 11px 15px;
}

body {
	color: #333;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 20px;
}

h4 {
	margin-top: 0;
	margin-bottom: 10px;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
}

h5 {
	color: var(--slate-grey);
}

a {
	text-decoration: none;
}

.strong {
	grid-column-gap: 5px;
	grid-row-gap: 5px;
	flex: 0 auto;
	justify-content: flex-start;
	align-items: center;
	margin-right: 16px;
	display: flex;
}

._60-off-car-batteries-use-code-batt60 {
	color: #fff;
	text-align: center;
	letter-spacing: 0.4px;
	margin-right: 20px;
	font-family: Roboto Condensed, sans-serif;
	font-size: 15px;
	font-weight: 700;
	line-height: 18px;
}

.body {
	color: var(--slate-grey);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Roboto, sans-serif;
	font-size: 15px;
}

.body:hover {
	border-radius: 25px;
}

.div-header-store-notice-2 {
	grid-column-gap: -0.0100098px;
	grid-row-gap: -0.0100098px;
	flex: 0 auto;
	justify-content: center;
	align-items: center;
	display: flex;
}

.vectors-wrapper-3 {
	width: 9.3709px;
	height: 15px;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	object-fit: cover;
	justify-content: center;
	align-items: center;
	display: flex;
}

.text-9 {
	color: var(--orange);
	text-align: center;
	letter-spacing: 0.4px;
	font-family: Roboto Condensed, sans-serif;
	font-size: 15px;
	font-weight: 700;
	line-height: 18px;
}

.brix---icon-small-hover {
	transition: transform 0.3s;
}

.brix---icon-small-hover:hover {
	transform: scale(0.9);
}

.brix---top-bar-close-icon {
	cursor: pointer;
	align-items: center;
	padding: 20px 32px;
	transition: opacity 0.3s;
	display: flex;
	position: absolute;
	top: 0%;
	bottom: 0%;
	right: 0%;
}

.brix---top-bar-right-col {
	margin-right: 24px;
	display: flex;
}

.brix---top-bar-flex-h-center {
	height: 45px;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	display: flex;
}

.brix---top-bar-wrapper {
	z-index: 3;
	width: 100%;
	background-color: var(--slate-grey);
	color: #fff;
	text-align: center;
	justify-content: center;
	padding-left: 72px;
	padding-right: 72px;
	font-family: Lato, sans-serif;
	font-size: 18px;
	line-height: 28px;
	position: relative;
	overflow: hidden;
}

.dividerline {
	width: 100%;
	height: 1px;
	background-color: var(--8);
}

.cf-visual-features-item {
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.cf-visual-features-item.navbar {
	color: #0d0e10;
	background-color: #fff;
	border-radius: 5px;
	padding: 40px;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	line-height: 125%;
}

.cf-visual-features-item.navbar:hover {
	background-color: #fafafa;
}

.cf-visual-features-item.navbar.blog {
	width: 210px;
	padding: 20px;
}

.cf-visual-features-item.navbar {
	color: #0d0e10;
	background-color: #fff;
	border-radius: 0;
	padding: 40px;
	text-decoration: none;
}

.cf-visual-features-item.navbar:hover {
	background-color: #fafafa;
}

.cf-nav-link-summary {
	font-family: Roboto, sans-serif;
	font-size: 16px;
}

.cf-navigation-item-heading {
	margin-top: 0;
	margin-bottom: 10px;
	font-family: Roboto, sans-serif;
}

.cf-logo-image-3 {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.cf-visual-features-item-image-2 {
	width: 100%;
	border-radius: 5px;
	margin-bottom: 28px;
	overflow: hidden;
}

.cf-visual-features-items-container {
	grid-column-gap: 40px;
	grid-row-gap: 0px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.cf-visual-features-items-container.nav {
	grid-column-gap: 0px;
}

.cf-visual-features-items-container.nav.blogs {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	padding-left: 0;
	padding-right: 0;
}

.cf-visual-features-items-container.nav {
	grid-column-gap: 0px;
}

.cf-navigation-drop-container-2 {
	width: 100%;
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	overflow: hidden;
}

.cf-navigation-drop-container-2.features {
	border-radius: 10px;
	justify-content: flex-start;
	align-items: stretch;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.cf-dropdown-arrow---nav-3 {
	margin-right: 0;
	font-size: 14px;
	position: static;
}

.cf-dropdown-nav-3 {
	justify-content: center;
	margin-left: 5px;
	margin-right: 5px;
	display: flex;
	position: static;
}

.menu-icon-3-png,
.menu-icon-4-png,
.menu-icon-2-png {
	object-fit: cover;
}

.cf-growth-wrapper {
	width: 100%;
	max-width: 360px;
	background-color: #fff;
	border-radius: 22px;
	padding: 48px 40px;
	box-shadow: 0 13px 100px rgba(199, 199, 199, 0.25);
}

._3-categories {
	width: 100%;
	grid-column-gap: 50px;
	grid-row-gap: 50px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 16px;
	display: flex;
}

.banner-copy-content {
	z-index: 3;
	width: 600px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 30px;
	display: flex;
	position: absolute;
}

.banner-copy-content.center {
	width: auto;
	align-items: center;
	margin-left: 0;
}

.titles-headings {
	text-transform: uppercase;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.titles-headings.newsletter {
	margin-top: 0;
	margin-bottom: 0;
}

.quantity-block {
	width: auto;
	color: #211f54;
	flex-direction: row;
	justify-content: center;
	display: flex;
}

.link-block-2 {
	color: var(--slate-grey);
	text-decoration: none;
	transition: all 0.25s;
}

.link-block-2:hover {
	transform: scale(1.2);
}

.property-spotlight-image-link-3 {
	width: 100%;
	height: 300px;
	background-image: url('/20181016082423/assets/images/5291030040448.png');
	background-position: 50% 75%;
	background-repeat: no-repeat;
	background-size: auto 180px;
	border-bottom: 1px solid #dadada;
	border-left: 1px #dadada;
	border-right: 1px #dadada;
	margin-bottom: 0;
	transition: all 0.3s;
	box-shadow: 0 0 2px 1px #dadada;
}

.property-spotlight-image-link-3:hover {
	opacity: 0.85;
}

.property-spotlight-image-link-3.product2 {
	height: auto;
	max-height: 85px;
	box-shadow: none;
	background-image: url('/20181016082423/assets/images/drapanokatsavido.png');
	padding-top: 75%;
}

.listing-details {
	z-index: 1;
	width: 100%;
	color: #b91313;
	text-align: left;
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 0.65rem;
	display: block;
	position: relative;
}

.listing-details.large-was-price {
	color: rgba(28, 43, 60, 0.64);
	font-size: 16px;
	font-weight: 400;
	text-decoration: line-through;
}

.listing-details.large-was-price.no-strike {
	text-decoration: none;
}

.listing-details.large-was-price.large {
	font-size: 1.5rem;
}

.listing-details.large-was-price-copy {
	color: rgba(28, 43, 60, 0.64);
	font-size: 1rem;
	font-weight: 400;
}

.text-block-5 {
	color: var(--slate-grey);
	line-height: 1.5rem;
}

.text-block-5:hover {
	transform: scale(1.2);
}

.text-block-5.text-quantity {
	color: #211f54;
}

.text-block-5.text-quantity:hover {
	transform: none;
}

.icon-5 {
	width: 35px;
	height: 35px;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 600;
	transition: all 0.2s;
	display: flex;
}

.icon-5:hover {
	color: #fff;
}

.link-block {
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: all 0.25s;
	display: flex;
}

.link-block:hover {
	transform: scale(1.2);
}

.widget-outer-warp {
	width: 100%;
}

.cart-wrapper {
	z-index: 2;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	border-radius: 1px;
	flex-wrap: nowrap;
	display: flex;
}

.cart-wrapper.secpnd {
	margin-top: -5px;
}

.cart-wrapper.spaced {
	width: 100%;
	flex-direction: row;
}

.prop-spotlight-details-wrapper-3 {
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
}

.slider-content {
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	transition: all 0.25s;
	display: flex;
}

.in-stock-product-code-div {
	width: 100%;
	text-align: center;
	border-top: 1px #dadada;
	border-bottom: 1px #dadada;
	justify-content: center;
	align-self: flex-start;
	margin-top: 0;
	padding-right: 0;
	display: flex;
}

.in-stock-product-code-div.withlines {
	justify-content: flex-start;
	margin-top: -10px;
}

.product-code {
	color: #1c2b3c;
	text-align: left;
	flex: 0 auto;
	font-size: 16px;
	font-weight: 300;
	line-height: 0.8rem;
}

.product-details {
	float: left;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 10px;
	margin-bottom: 16px;
	display: flex;
}

.quantity-element {
	width: 24px;
	height: 24px;
	color: #f7f7f7;
	justify-content: center;
	align-items: center;
	margin-top: 4px;
	margin-bottom: 4px;
	padding: 0 6px;
	font-size: 1.2rem;
	text-decoration: none;
	display: flex;
}

.quantity-element:hover {
	text-decoration: none;
}

.quantity-element.quantity-form {
	color: var(--slate-grey);
	border-right-style: none;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;
}

.quantity-element.block-right {
	width: 24px;
	height: 24px;
	border-right-style: none;
	text-decoration: none;
}

.quantity-element.block-right:hover {
	text-decoration: none;
}

.price-text-wrapper {
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.price-text-wrapper.spaced {
	grid-row-gap: 16px;
	margin-top: 32px;
	margin-bottom: 20px;
}

.refined-widget {
	width: 100%;
	height: auto;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: center;
	display: flex;
}

.refined-widget.mob {
	display: none;
}

.product-name {
	width: 100%;
	/*height: 5ch;*/
	color: var(--slate-grey);
	text-align: left;
	cursor: pointer;
	margin-bottom: 0;
	margin-right: 0;
	font-size: 16px;
	font-weight: 400;
	transition: all 0.2s cubic-bezier(1, 0, 0, 1);
	display: flex;
	overflow: hidden;
}

.product-name:hover {
	color: var(--orange);
}

.discount {
	color: #fff;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	background-color: #131313;
	border-radius: 6px;
	padding: 14px 15px;
	font-size: 16px;
	line-height: 18px;
	display: inline-block;
}

.discount.small {
	padding: 12px;
	font-size: 12px;
	line-height: 14px;
}

.discount.small.light.category {
	height: fit-content;
	z-index: 2;
	background-color: var(--bright-red);
	color: #fff;
	border-radius: 20px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 5px;
	padding-bottom: 3px;
	font-size: 12px;
	line-height: 17px;
	text-decoration: none;
	transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.clearance {
	color: #fff;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	background-color: #131313;
	border-radius: 6px;
	padding: 14px 15px;
	font-size: 16px;
	line-height: 18px;
	display: inline-block;
}

.clearance.small {
	padding: 12px;
	font-size: 12px;
	line-height: 14px;
}

.clearance.small.light.category {
	height: fit-content;
	z-index: 2;
	background-color: var(--orange);
	border-radius: 20px;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 3px;
	line-height: 17px;
	transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	display: flex;
}

.copy-content-wrapper {
	height: 100%;
	background-position: 0 0, 50%;
	background-size: auto, cover;
	border-radius: 5px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	display: flex;
}

.copy-content-wrapper.small {
	background-image: url('/20181016082423/assets/images/Dewalt_Warranty_BANNER1000x600.jpg');
	background-position: 50%;
	background-size: cover;
}

.title-container {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	display: flex;
	position: relative;
}

.tab-link-all {
	background-color: var(--4);
	margin-left: 3px;
	margin-right: 3px;
	font-size: 16px;
}

.tab-link-all.w--current {
	background-color: var(--slate-grey);
	color: #fff;
	border-radius: 5px;
}

.tab-link {
	font-size: 16px;
	font-weight: 500;
}

.tabs-menu {
	justify-content: center;
	display: flex;
}

.tabs {
	width: 100%;
}

.special-square-banner {
	width: 33%;
	min-height: 300px;
	min-width: 200px;
	border-radius: 10px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	overflow: hidden;
}

.uui-navbar02_blog-item {
	background-color: rgba(246, 247, 247, 0.75);
	border-radius: 0.5rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: max-content;
	grid-auto-columns: 1fr;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_blog-item:hover {
	background-color: #f6f7f7;
}

.uui-navbar02_item-right {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_dropdown-blog-item-wrapper {
	flex-direction: column;
	grid-template-rows: auto auto auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
}

.uui-navbar02_blog-content {
	flex-direction: column;
	justify-content: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_blog-image-wrapper {
	width: 100%;
	border-radius: 0.375rem;
	margin-bottom: 1.5rem;
	padding-top: 55%;
	position: relative;
	overflow: hidden;
}

.uui-navbar02_item-heading {
	color: #475467;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 15px;
	font-weight: 400;
	line-height: 10px;
}

.uui-navbar02_item-heading:hover {
	-webkit-text-stroke-color: #333;
	border: 0 solid #333;
	text-decoration: underline;
}

.uui-navbar02_dropdown-content-button-wrapper {
	letter-spacing: normal;
	margin-top: 0.75rem;
	font-size: 0.875rem;
}

.uui-navbar02_blog-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.uui-button-row {
	grid-column-gap: 0.75rem;
	grid-row-gap: 0.75rem;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}

.uui-button-link {
	grid-column-gap: 0.5rem;
	color: #6941c6;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link:hover {
	color: #53389e;
}

.uui-button-link.is-button-xsmall {
	font-size: 0.875rem;
}

.uui-navbar02_dropdown-content-wrapper {
	z-index: 1;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
	position: relative;
}

.main_container__social-media-container {
	width: 100%;
	grid-column-gap: 80px;
	grid-row-gap: 60px;
	justify-content: center;
	margin-top: 10px;
	margin-bottom: 0;
	display: flex;
	position: relative;
}

.social-media-link__social-media-image {
	width: 6em;
	height: 6em;
	margin: auto auto 25px;
}

.social-media-link__social-media-image.small {
	width: 5.8em;
	height: 5.8em;
}

.feature {
	color: var(--slate-grey);
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	margin-bottom: 5px;
	font-family: Roboto Condensed, sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
}

.feature.cblink {
	cursor: default;
}

.feature-container {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.feature-description {
	text-align: center;
	font-size: 16px;
	line-height: 17px;
}

._3-blocks-container {
	width: 100%;
	max-width: 1550px;
	grid-column-gap: 40px;
	grid-row-gap: 40px;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 60px;
	display: flex;
}

.signup {
	width: 100%;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 100px;
	display: flex;
}

.uui-footer05_social-icon {
	width: 2rem;
}

.footer_social-link {
	color: var(--untitled-ui-gray400);
	justify-content: space-between;
	align-self: center;
	align-items: center;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.footer_social-link:hover {
	color: var(--orange);
}

.footer_legal-link {
	color: var(--30);
	font-size: 16px;
	text-decoration: none;
	transition: all 0.3s;
}

.footer_legal-link:hover {
	color: #fff;
}

.footer_legal-list {
	display: inline-block;
}

.footer-small-text {
	color: var(--untitled-ui-gray600);
	letter-spacing: normal;
	padding-left: 5px;
	font-size: 16px;
	line-height: 1.5;
}

.footer-small-text.text-color-gray500 {
	color: #fff;
}

.footer-small-text.text-color-gray500 h1 {
	display: inline;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	margin: 0px;
	padding: 0px;
	font-weight: inherit;
}

.footer_legal-list-wrapper {
	grid-column-gap: 1.5rem;
	display: flex;
}

.footer_bottom-wrapper {
	width: 100%;
	border-top: 1px solid var(--60);
	justify-content: space-between;
	align-items: center;
	margin-top: 2.5rem;
	padding-top: 2rem;
	display: flex;
}

.uui-footer05_link {
	grid-column-gap: 0.5rem;
	color: var(--30);
	flex-wrap: nowrap;
	align-items: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 16px;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-footer05_link:hover {
	color: #fff;
}

.uui-footer05_link.hidden {
	display: none;
}

.uui-footer05_link-list-heading {
	color: var(--orange);
	letter-spacing: normal;
	margin-bottom: 1rem;
	font-size: 16px;
}

.footer_link-list {
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 100%;
	grid-auto-columns: 100%;
	align-items: flex-start;
	justify-items: start;
	display: flex;
}

.uui-logo_logotype {
	width: auto;
	height: 100%;
	flex: none;
}

.uui-logo_component {
	width: auto;
	height: 6.3rem;
	flex-wrap: nowrap;
	display: flex;
}

.footer_top-wrapper {
	grid-column-gap: 2rem;
	grid-row-gap: 1rem;
	grid-template-rows: auto;
	grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
	align-items: start;
	justify-items: start;
}

.error-message {
	margin-top: 1.5rem;
	padding: 0.875rem 1rem;
}

.success-message {
	background-color: #f4f4f4;
	padding: 1.5rem;
}

.uui-text-style-link {
	color: var(--untitled-ui-gray600);
	text-decoration: underline;
	transition: color 0.3s;
}

.uui-text-style-link:hover {
	color: var(--untitled-ui-gray700);
}

.uui-form_input {
	border: 1px solid var(--untitled-ui-gray300);
	background-color: var(--untitled-ui-white);
	color: var(--untitled-ui-gray900);
	border-radius: 0.5rem;
	margin-bottom: 0;
	padding-top: 3px;
	padding-left: 0.875rem;
	padding-right: 0.875rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	line-height: 0.3;
	transition: all 0.3s;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-form_input:focus {
	color: var(--untitled-ui-gray900);
	border-color: rgba(255, 128, 0, 0.54);
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(255, 128, 0, 0.06);
}

.uui-form_input::-ms-input-placeholder {
	color: var(--60);
}

.uui-form_input::placeholder {
	color: var(--60);
}

.uui-footer05_form {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr max-content;
	grid-auto-columns: 1fr;
	margin-bottom: 0.75rem;
	line-height: 17px;
	display: grid;
}

.uui-footer05_form-block {
	width: 65%;
	min-width: 25rem;
	margin-bottom: 0;
}

.uui-footer05_newsletter-wrapper {
	width: 65%;
	grid-column-gap: 32px;
	background-color: var(--8);
	border-radius: 5px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 55px;
	display: flex;
}

.footer-padding-vertical {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 4rem;
	padding-bottom: 4rem;
	display: flex;
}

.footer-container {
	max-width: 1550px;
	justify-content: center;
	display: flex;
}

.footer-padding {
	justify-content: center;
	display: flex;
}

.sozos-footer {
	width: 100%;
	background-color: var(--slate-grey);
	color: var(--4);
	padding-left: 5%;
	padding-right: 5%;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 29px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.footer-grip-link.white-logo {
	width: 140px;
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
}

.grip-logo-text-box-2 {
	color: #000;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5rem;
	text-decoration: none;
}

.grip-logo-text-box-2.webdevby-white {
	color: #fff;
}

.tab-pane-exteriors {
	width: 100%;
}

.tabs-content {
	padding-top: 0;
	overflow: visible;
}

.stilhi {
	width: 100%;
	height: 100%;
	background-position: 0 0, 100% 65%;
	background-size: auto, cover;
}

.stilhi.square {
	object-fit: fill;
	background-position: 50%;
	background-repeat: repeat;
	background-size: cover;
	border-radius: 10px;
	display: flex;
}

.primary-btn {
	z-index: 5;
	background-color: var(--orange);
	border-radius: 5px;
	padding-top: 9px;
	padding-bottom: 9px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 700;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	position: relative;
}

.primary-btn:hover {
	background-color: var(--slate-grey);
	transform: translate(0, -3px);
}

.secondary-btn {
	color: var(--slate-grey);
	text-transform: uppercase;
	background-color: #fff;
	border-radius: 5px;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.secondary-btn:hover {
	background-color: var(--orange);
	color: #fff;
}

.category-link {
	width: 16.6%;
	border-radius: 5px;
	flex-direction: column;
	flex: 0 auto;
	align-content: stretch;
	justify-content: space-around;
	align-items: center;
	margin-right: 20px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 20px;
	text-decoration: none;
	display: flex;
}

.category-text {
	height: auto;
	color: var(--slate-grey);
	text-align: center;
	letter-spacing: 0.75px;
	text-transform: capitalize;
	object-fit: fill;
	margin-top: 20px;
	margin-left: 0;
	padding-bottom: 0;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.1rem;
	text-decoration: none;
	display: block;
}

.wishlist-save-icon-2 {
	width: 40px;
	height: 40px;
	max-height: 22px;
	max-width: 22px;
	min-height: 22px;
	min-width: 22px;
	background-image: url('/20181016082423/assets/images/heart.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 22px 22px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: 0;
	transition: all 0.2s;
	display: flex;
	position: static;
	top: 5%;
	right: 7%;
}

.wishlist-save-icon-2:hover {
	background-image: url('/20181016082423/assets/images/heart---orange-full.svg');
}

.dropdown-toggle {
	margin-right: 16px;
	padding-left: 0;
	display: flex;
	position: relative;
	top: -10px;
	right: 0;
}

.language-dropdown-link {
	background-color: #f1f1f1;
	background-image: url('/20181016082423/assets/images/flags-homepage-icons-44.svg');
	background-position: 8px;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	border: 1px solid #f1f1f1;
	padding: 0.25rem 0.75rem 0.25rem 2.5rem;
	font-size: 0.7rem;
	font-weight: 500;
	transition: all 0.45s;
}

.language-dropdown-link:hover {
	background-color: #fff;
}

.language-dropdown-link._1 {
	background-image: none;
	border-radius: 5px 5px 0 0;
	justify-content: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	display: flex;
}

.language-dropdown-link._2 {
	background-image: none;
	border-radius: 0 0 5px 5px;
	justify-content: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	display: flex;
}

.language-dropdown-link._1 {
	background-color: #fff;
	background-image: none;
	border-radius: 5px 5px 0 0;
	justify-content: center;
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	font-size: 14px;
	display: flex;
}

.language-dropdown-link._1:hover {
	background-color: #f1f1f1;
}

.language-dropdown-link._2 {
	text-align: left;
	background-color: #fff;
	background-image: none;
	border-radius: 0 0 5px 5px;
	justify-content: flex-start;
	padding-left: 0.8rem;
	font-size: 14px;
	display: flex;
}

.language-dropdown-link._2:hover {
	background-color: #f1f1f1;
}

.language-dropdown-list {
	z-index: 1000;
}

.language-dropdown-list.w--open {
	background-color: rgba(255, 255, 255, 0);
	margin-top: -16px;
	padding-top: 2.5rem;
	right: 0;
}

.titles-headings-copy {
	color: var(--slate-grey);
	text-align: center;
	text-transform: capitalize;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 0;
	font-size: 38px;
	line-height: 40px;
	display: inline-block;
}

.nav-menu {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: center;
	display: flex;
}

.right-arrow-2 {
	width: 50px;
	height: 50px;
	background-color: #fff;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 1px solid rgba(30, 34, 40, 0.08);
	border-radius: 100%;
	margin-right: 0;
	transition: all 0.35s;
	top: auto;
	bottom: 0.125rem;
	left: auto;
	right: 2vw;
}

.right-arrow-2:hover {
	background-color: #dd0404;
	border-color: #dd0404;
}

.left-arrow-2 {
	width: 50px;
	height: 50px;
	background-color: #fff;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 1px solid rgba(30, 34, 40, 0.08);
	border-radius: 100%;
	margin-left: 0;
	transition: all 0.35s;
	top: auto;
	bottom: 0.125rem;
	left: auto;
	right: 6.5vw;
}

.left-arrow-2:hover {
	background-color: #dd0404;
	background-image: url('/20181016082423/assets/images/arrow-left.svg');
	border-color: #dd0404;
}

.was-wording {
	z-index: 1;
	color: var(--60);
	text-align: right;
	font-size: 1rem;
	font-weight: 700;
	line-height: 0.65rem;
	display: block;
	position: relative;
}

.price-text-wrapper-alternative {
	grid-column-gap: 12px;
	grid-row-gap: 5px;
	text-align: left;
	flex-direction: row;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1.5fr;
	grid-auto-columns: 1fr;
	justify-content: start;
	align-self: flex-start;
	justify-items: start;
	margin-top: 20px;
	margin-bottom: 20px;
	display: none;
}

.is-now {
	z-index: 1;
	width: 100%;
	color: var(--orange);
	text-align: right;
	font-size: 1rem;
	font-weight: 700;
	line-height: 0.65rem;
	display: block;
	position: relative;
}

.listing-details-copy {
	z-index: 1;
	width: 100%;
	color: #b91313;
	text-align: left;
	z-index: 1;
	width: 100%;
	color: var(--60);
	text-align: center;
	padding-left: 5px;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 0.65rem;
	display: block;
	position: relative;
}

.orange-price {
	color: var(--orange);
	font-size: 1.4rem;
	font-weight: 900;
	line-height: 1.75rem;
}

.orange-price.large {
	font-size: 2.5rem;
}

.cf-dropdown-list-full-wrapper-2-copy {
	width: 240px;
	background-color: #fff;
	border-radius: 5px;
	flex-direction: row;
	justify-content: flex-start;
	padding: 20px 16px;
	display: flex;
	box-shadow: 0 30px 100px rgba(64, 69, 79, 0.1);
}

.cf-dropdown-list-full-wrapper-2-copy.blog {
	width: auto;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	padding-left: 25px;
	padding-right: 25px;
	position: relative;
}

.tab-change_tab-content {
	align-self: auto;
}

.uui-navbar02_dropdown-link-2 {
	grid-column-gap: 1rem;
	grid-row-gap: 0px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content;
	grid-auto-columns: 1fr;
	align-items: flex-start;
	margin-left: 0;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-right: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: grid;
}

.uui-navbar02_dropdown-link-2:hover {
	background-color: rgba(0, 0, 0, 0);
}

.uui-navbar02_dropdown-link-list {
	grid-column-gap: 0px;
	grid-row-gap: 0.5rem;
	flex-direction: column;
	grid-template-rows: 1fr 1fr auto auto;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	align-self: center;
	display: flex;
}

.tab-change_style-tab {
	justify-content: center;
	align-items: stretch;
	margin-top: 0;
	display: flex;
}

.tab-change_tab-link {
	color: #d7d2c7;
	background-color: rgba(0, 0, 0, 0);
	align-items: center;
	padding: 8px 0;
	display: flex;
	overflow: hidden;
}

.tab-change_tab-link.w--current {
	color: #cfb889;
	background-color: rgba(0, 0, 0, 0);
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
}

.uui-navbar02_dropdown-content-3 {
	justify-content: center;
	margin-top: 0;
	display: flex;
}

.cf-dropdown-toggle---nav-3 {
	grid-column-gap: 6px;
	color: #04121f;
	border-bottom: 4px solid rgba(0, 0, 0, 0);
	padding: 6px 12px 1px 0;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	font-weight: 500;
	transition: color 0.2s;
	display: flex;
}

.cf-dropdown-toggle---nav-3:hover {
	outline-offset: 0px;
	color: #ff8000;
	border-bottom: 4px solid #ff8000;
	outline: 3px #ff8000;
	font-weight: 500;
}

.cf-dropdown-toggle---nav-3.w--open {
	padding-left: 0;
}

.uui-navbar02_dropdown-content-left-3 {
	grid-column-gap: 2rem;
	grid-row-gap: 0px;
	flex: 1;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-top: 0;
	padding: 0 0 2rem;
	display: flex;
}

.cf-dropdown-list--nav-3 {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 8px;
	top: 70px;
	left: 0;
	right: 0;
	overflow: hidden;
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.08);
}

.cf-dropdown-list--nav-3.w--open {
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: flex-start;
	padding-top: 40px;
	display: flex;
	overflow: visible;
	max-width: 600px;
	min-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.uui-navbar02_container-3 {
	justify-content: center;
	align-self: center;
	align-items: flex-start;
	display: flex;
}

.cf-dropdown-list-full-wrapper-3 {
	background-color: #fff;
	border-radius: 5px;
	flex-direction: row;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	display: flex;
	box-shadow: 0 30px 100px rgba(64, 69, 79, 0.1);
}

.text-block-46 {
	color: #160042;
}

.text-block-46:hover {
	color: #344054;
}

.tab-change_tab-menu-2 {
	width: 230px;
	border-right: 1px solid rgba(28, 43, 60, 0.5);
	flex-direction: column;
	align-items: flex-start;
	margin-top: 0;
	margin-right: 10px;
	padding-right: 30px;
	display: flex;
}

.last-item-on-carousel {
	width: 100%;
	color: var(--30);
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	display: flex;
	position: static;
}

.last-item-on-carousel:hover {
	color: var(--slate-grey);
}

.heading-3 {
	text-transform: none;
	margin-bottom: 0;
	font-family: Roboto Condensed, sans-serif;
	font-size: 18px;
	line-height: 20px;
}

.icon-5-copy {
	cursor: pointer;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	margin: auto;
	font-size: 5rem;
	font-style: normal;
	font-weight: 600;
	display: flex;
}

.icon-5-copy:hover {
	margin-left: auto;
	margin-right: auto;
}

.heading-4 {
	margin-top: 10px;
	margin-bottom: auto;
	font-size: 22px;
}

.hero-section {
	width: 100%;
	aspect-ratio: 155 / 60;
	max-width: 1550px;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	padding-top: 20px;
	padding-bottom: 20px;
	display: grid;
}

.small-banner {
	border-radius: 10px;
}

.small-banner._1 {
	background-image: url('/20181016082423/assets/images/sample.jpg');
	background-position: 50% 0;
	background-size: cover;
	border-radius: 5px;
}

.categories-section {
	width: 100%;
	max-width: 1550px;
	flex-direction: column;
	justify-content: flex-start;
	align-self: center;
	align-items: center;
	display: flex;
}

.categories-grid {
	width: 100%;
	grid-column-gap: 0px;
	grid-row-gap: 20px;
	flex-direction: column;
	grid-template-rows: 1fr minmax(150px, 1fr);
	grid-template-columns: auto auto auto auto auto auto;
	grid-auto-columns: 1fr;
	align-content: center;
	justify-content: space-between;
	justify-items: stretch;
	margin-top: 20px;
	display: flex;
}

.category-img {
	width: 100%;
	background-color: var(--8);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 5px;
	padding-top: 50%;
	padding-bottom: 50%;
}

.section-wrapper {
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-self: center;
	padding: 100px 5%;
	display: flex;
}

.section-wrapper.vertical {
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
}

.section-wrapper.vertical.grey-bg {
	background-color: var(--4);
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
}

.section-wrapper.vertical.hide {
	display: none;
}

.section-wrapper.vertical.less-top-padding {
	padding-top: 20px;
}

.section-wrapper.grey-bg {
	background-color: var(--4);
	padding-top: 40px;
	padding-bottom: 40px;
}

.tabs-section {
	width: 100%;
	max-width: 1570px;
	justify-content: center;
	align-self: center;
	display: flex;
}

.tab-pane-all {
	height: 100%;
}

.img-link-row {
	width: 100%;
	max-width: 1550px;
	grid-column-gap: 45px;
	grid-row-gap: 0px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	align-content: center;
	justify-content: space-between;
	align-self: center;
	align-items: center;
	justify-items: stretch;
	display: grid;
}

.generic-img {
	perspective-origin: 50% 100%;
	transform-origin: 50% 100%;
	border-radius: 5px;
}

.img-promo-link {
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.link-text {
	color: var(--slate-grey);
	text-align: center;
	margin-top: 10px;
	font-size: 16px;
	text-decoration: none;
}

.link-text:hover {
	text-decoration: none;
}

.secondary-title-wrap {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	display: flex;
}

.secondary-title {
	text-align: center;
	font-size: 38px;
}

.promo-paragraph {
	color: var(--slate-grey);
	font-size: 16px;
	text-align: center;
}

.uui-blogsection04_item {
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.uui-blogsection04_category-link {
	color: #6941c6;
	background-color: #fff;
	border-radius: 1rem;
	align-items: center;
	padding: 0.125rem 0.5rem 0.125rem 0;
	font-size: 0.75rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-blogsection04_category-link:hover {
	color: #53389e;
}

.text-block-35 {
	color: var(--slate-grey);
	-webkit-text-stroke-color: #004ba0;
	margin-left: 10px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
}

.product-slider {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	align-items: stretch;
	display: flex;
	margin-top: 30px;
}

.uui-text-size-medium-7 {
	color: var(--slate-grey);
	letter-spacing: normal;
	margin-bottom: 10px;
	margin-left: 10px;
	font-size: 16px;
	line-height: 1.3;
}

.uui-text-size-medium-7 p:last-child {
	margin-bottom: 0px;
}

.blog-title {
	color: var(--orange);
	letter-spacing: 0;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 20px;
	font-weight: 600;
	line-height: 1.4;
}

.uui-blogsection04_meta-wrapper {
	color: #6941c6;
	mix-blend-mode: multiply;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 10rem;
	align-items: center;
	padding: 0.25rem 0.25rem 0.25rem 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 0.75rem;
	font-weight: 500;
	display: flex;
}

.uui-blogsection04_image-wrapper {
	width: 100%;
	border-radius: 10px;
	padding-top: 66%;
	position: relative;
	overflow: hidden;
}

.uui-blogsection04_image-link {
	width: 100%;
	margin-bottom: 0.5rem;
	transition: all 0.3s;
}

.uui-blogsection04_image-link:hover {
	box-shadow: 0 24px 48px -12px rgba(16, 24, 40, 0.18);
}

.product-slider-arrow {
	width: 45px;
	height: 45px;
	background-color: var(--4);
	color: var(--60);
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	font-size: 1.25rem;
	font-weight: 700;
	transition: all 0.3s;
	display: flex;
	top: -20%;
	bottom: auto;
	left: auto;
	right: 5rem;
}

.product-slider-arrow:hover {
	background-color: var(--slate-grey);
	color: #fff;
}

.product-slider-arrow.property-arrow-right {
	margin-right: 1rem;
	bottom: auto;
	left: auto;
	right: 0%;
}

.product-slider-arrow.property-arrow-right:hover {
	background-color: var(--slate-grey);
}

.product-slider-arrow.property-arrow-right.product {
	color: var(--60);
	background-color: #fff;
	margin-right: 1.4rem;
	right: -8px;
}

.product-slider-arrow.property-arrow-right.product:hover {
	background-color: var(--slate-grey);
	color: #fff;
}

.product-slider-arrow.property-arrow-right.itm {
	background-color: #fff;
	margin-right: 1.4rem;
	right: -8px;
}

.product-slider-arrow.property-arrow-right.itm:hover {
	background-color: var(--orange);
}

.product-slider-arrow.property-arrow-right.lvl2slider {
	color: var(--60);
	top: 10%;
	right: 3rem;
}

.product-slider-arrow.property-arrow-right.lvl2slider:hover {
	background-color: var(--slate-grey);
	color: #fff;
}

.product-slider-arrow.product {
	color: var(--60);
	background-color: #fff;
	right: 5rem;
}

.product-slider-arrow.product:hover {
	background-color: var(--slate-grey);
	color: #fff;
}

.product-slider-arrow.itm {
	background-color: #fff;
	margin-top: -110px;
	right: 5rem;
}

.product-slider-arrow.itm:hover {
	background-color: var(--orange);
	color: var(--orange);
}

.product-slider-arrow.blog {
	top: -12%;
}

.product-slider-arrow.blog.lvl2slider {
	color: var(--60);
	cursor: pointer;
	top: 10%;
	right: 8rem;
}

.product-slider-arrow.blog.lvl2slider:hover {
	background-color: var(--slate-grey);
	color: #fff;
}

.product-slider-arrow.itm-copy {
	background-color: #fff;
	margin-top: -110px;
	right: 5rem;
}

.product-slider-arrow.itm-copy:hover {
	background-color: var(--orange);
	color: var(--orange);
}

.product-slider-arrow.category {
	background-color: #fff;
	margin-top: -110px;
	right: 5rem;
}

.product-slider-arrow.category:hover {
	background-color: var(--orange);
	color: var(--orange);
}

.uui-blogsection04_image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.product-spotlight-slide {
	width: 33.33%;
	min-height: 300px;
	min-width: 200px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 10px;
	flex-direction: column;
	flex-shrink: 0;
	padding-left: 12.5px;
	padding-right: 12.5px;
}

.product-spotlight-slide.brand {
	width: 33.33%;
	margin-right: 0;
}

.icon {
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	font-size: 1.3rem;
	transition: all 0.2s;
	display: flex;
}

.uui-blogsection04_title-link {
	margin-bottom: 0.5rem;
	margin-left: 10px;
	text-decoration: none;
	display: block;
}

.blog-container {
	max-width: 1575px;
}

.product-spotlight-mask {
	width: 100%;
	flex-wrap: nowrap;
	margin-top: 2rem;
	display: flex;
	overflow: hidden;
}

.phone-div {
	height: 100%;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-family: Lato, sans-serif;
	font-weight: 400;
	display: flex;
}

.slider-3 {
	height: 100%;
	background-image: linear-gradient(45deg, #000, #fff 80%);
	border-radius: 5px;
	overflow: hidden;
}

.slide-nav-3 {
	height: 30px;
	background-color: #fff;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding: 7px 14px;
	position: relative;
	z-index: 1000;
	top: -50px;
	display: inline-block;
}

.slide {
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	display: flex !important;
	align-items: center;
	justify-items: center;
}

.slide-2 {
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.copy-content-wrapper-2 {
	width: 70%;
	text-shadow: 0 3px 1px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 100px;
	margin-right: auto;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-right: 20px;
	display: flex;
}

.hero-secondary-heading {
	color: #fff;
	text-align: center;
	font-family: Roboto Condensed, sans-serif;
	font-weight: 600;
}

.hero-heading {
	color: #fff;
	text-align: center;
	margin-top: 0;
	font-family: Roboto Condensed, sans-serif;
	font-size: 60px;
}

.hero-paragraph {
	width: 65%;
	color: #fff;
	text-align: left;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
}

.primary-btn-2 {
	z-index: 5;
	text-transform: capitalize;
	text-shadow: none;
	background-color: #ff8000;
	border-radius: 5px;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	position: relative;
}

.primary-btn-2:hover {
	background-color: #1c2b3c;
	transform: translate(0, -3px);
}

.div-block {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.icon-wrapper {
	padding: 4px 4px 10px;
}

.partners-module {
	width: 100%;
	max-width: 1575px;
}

.labels {
	grid-column-gap: 5px;
	grid-row-gap: 5px;
	flex-wrap: wrap;
	display: flex;
}

.labels.space {
	margin-bottom: 16px;
}

.labels.space.mobile {
	display: none;
}

.delivery {
	height: 100%;
	color: #1c2b3c;
	text-align: left;
	background-image: url('/20181016082423/assets/images/delivery-solid-1c2b3c.svg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 26px;
	flex: 0 auto;
	padding-top: 6px;
	padding-left: 32px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
}

.search-bar-2 {
	z-index: 10000;
	width: 666px !important;
	background-color: #fff;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	flex-direction: column;
	justify-content: space-around;
	align-items: stretch;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1rem;
	padding-right: 1rem;
	position: fixed;
	bottom: auto;
	left: auto;
	right: 8%;
	top: 0;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.search-bar-wrap-2 {
	height: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	padding-right: 15px;
	display: flex;
}

.search-input-3 {
	width: 95%;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	margin-bottom: 0;
	padding: 1.8rem 3.1rem 1.8rem 1rem;
	font-size: 0.85rem;
	font-weight: 300;
	position: relative;
}

.search-input-exit-2 {
	width: 5%;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	display: block;
}

.search-input-exit-2.two {
	z-index: 5;
	width: 20px;
	padding: 0 0;
	display: none;
	position: relative;
}

.x-close {
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
}

.vectors-wrapper-6 {
	width: 27px;
	height: 27px;
	max-width: none;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	object-fit: cover;
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
	top: -2px;
}

.cf-dropdown-list-full-wrapper-4 {
	width: 240px;
	background-color: #fff;
	border-radius: 5px;
	flex-direction: row;
	justify-content: flex-start;
	padding: 20px 0 20px 16px;
	display: flex;
	box-shadow: 0 30px 100px rgba(64, 69, 79, 0.1);
}

.uui-navbar02_dropdown-content-left-5 {
	grid-column-gap: 2rem;
	grid-row-gap: 0px;
	flex: 1;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-top: 0;
	margin-left: 20px;
	padding-top: 0;
	padding-left: 0;
	padding-right: 1rem;
	display: flex;
}

.text-16 {
	color: #fff;
	text-align: center;
	letter-spacing: 0.4px;
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
}

.uui-text-size-small-5 {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
}

.uui-text-size-small-5.text-style-2lines {
	margin-top: 10px;
}

.tab-link-all-2 {
	background-color: #f6f7f7;
	margin-left: 3px;
	margin-right: 3px;
}

.tab-link-all-2.w--current {
	color: #fff;
	background-color: #1c2b3c;
	border-radius: 5px;
}

.tab-link-all-2.menu {
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	margin-left: 0;
	margin-right: 0;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 5px;
}

.tab-link-all-2.menu:hover {
	background-color: rgba(246, 247, 247, 0.36);
}

.tab-link-all-2.menu.w--current {
	color: #fff;
	background-color: #ff8000;
}

.tab-link-all-2.menu.w--current:hover {
	color: #1c2b3c;
}

.search {
	justify-content: center;
	align-items: center;
	margin-right: 20px;
	display: flex;
}

.login {
	width: 27px;
	height: 27px;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	cursor: pointer;
	object-fit: cover;
	justify-content: center;
	align-items: center;
	padding-right: 0;
	display: flex;
}

.toggle-2 {
	grid-column-gap: 6px;
	color: #04121f;
	text-transform: capitalize;
	border-bottom: 4px solid rgba(0, 0, 0, 0);
	padding: 6px 12px 1px 0;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	font-weight: 500;
	transition: color 0.2s;
	display: flex;
}

.toggle-2:hover {
	outline-offset: 0px;
	color: #ff8000;
	border-bottom: 4px solid #ff8000;
	outline: 3px #ff8000;
	font-weight: 500;
}

.toggle-2.w--open {
	justify-content: center;
	padding-left: 0;
}

.cf-dropdown-nav-5 {
	justify-content: center;
	margin-left: 5px;
	margin-right: 5px;
	position: static;
}

.search-icon {
	width: 40px;
	height: 40px;
	min-height: auto;
	background-color: #ff8000;
	background-image: url('/20181016082423/assets/images/brix-search-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 17px;
	border-radius: 5px;
	padding: 0 0.25rem;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0.3s;
}

.search-icon:hover {
	background-color: #1c2b3c;
}

.header-content-wrapper-2 {
	width: 100%;
	max-width: 1550px;
	grid-column-gap: 45px;
	grid-row-gap: 45px;
	grid-template-rows: auto;
	grid-template-columns: 0.5fr 1fr 0.75fr;
	grid-auto-columns: 1fr;
	align-content: end;
	justify-content: space-between;
	align-items: center;
	justify-items: stretch;
	margin-left: auto;
	margin-right: auto;
	display: grid;
}

.language-text-2 {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 700;
	line-height: 0.75rem;
}

.div-header-right-2 {
	grid-column-gap: 6px;
	grid-row-gap: 6px;
	flex: 0 auto;
	justify-content: center;
	align-items: flex-end;
	margin-left: 16px;
	padding-left: 5px;
	padding-right: 5px;
	display: flex;
}

.drop-down-list-2 {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 8px;
	top: 80px;
	left: 0;
	right: 0;
	overflow: hidden;
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.08);
}

.drop-down-list-2.w--open {
	box-shadow: none;
	background-color: #f6f7f7;
	border-radius: 5px;
	justify-content: center;
	margin-top: 15px;
	margin-left: 0;
	margin-right: 0;
	display: flex;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
	overflow: visible;
}

.drop-down-list-2.blog.w--open {
	background-color: rgba(0, 0, 0, 0);
	justify-content: flex-end;
	margin-top: 0;
	margin-right: auto;
	padding-top: 40px;
	top: 70px;
	bottom: auto;
	left: 0%;
	right: 0%;
	max-width: 800px;
	min-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.header-logo-link {
	transform-style: preserve-3d;
	padding-left: 0;
	min-width: 60px;
	transition: transform 0.3s, color 0.3s;
}

.header-logo-link:hover {
	transform: scale3d(0.96, 0.96, 1.01);
}

.header-logo-link.w--current {
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	display: flex;
	position: static;
}

.text-17 {
	color: #1c2b3c;
	letter-spacing: 0.4px;
	text-transform: capitalize;
	font-family: Roboto Condensed, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 32px;
}

.dropdown-3 {
	max-height: 32px;
	margin-right: -35px;
}

.text-18 {
	color: #1c2b3c;
	font-family: Roboto Condensed, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 32px;
}

.language-div-2 {
	margin-left: 16px;
	display: flex;
}

.uui-navbar02_dropdown-content-right-4 {
	max-width: 22rem;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	border-radius: 5px;
	flex: 1;
	grid-template-rows: max-content auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	margin-left: 30px;
	padding: 0 1.25rem 1.25rem;
	display: block;
	position: relative;
}

.link-08-9229-8230 {
	width: 100%;
	color: #1c2b3c;
	font-family: Roboto Condensed, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

.span-count-number-2 {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	background-color: #1c2b3c;
	border-radius: 11px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: -21px;
	padding-left: 7px;
	padding-right: 7px;
	display: flex;
	position: absolute;
	top: -1px;
	left: 23px;
}

.header-wrapper {
	width: 100%;
	height: 100px;
	background-color: #fff;
	border-bottom: 3px solid #ff8000;
	justify-content: space-between;
	align-items: center;
	padding: 16px 5%;
	display: flex;
	position: sticky;
	top: -1px;
}

.ecommerce-icon-div-2 {
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.link-2 {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	cursor: pointer;
	flex: 0 auto;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 10px;
	padding-right: 0;
	display: flex;
	position: relative;
}

.mob-phone-icon-2 {
	width: 23px;
	height: 20px;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	cursor: pointer;
	object-fit: cover;
	justify-content: center;
	align-items: center;
	padding-right: 0;
	display: none;
}

.container-6 {
	height: 100%;
	max-width: 1280px;
	min-height: 100%;
	padding-left: 20px;
	padding-right: 20px;
}

.container-6.footer {
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 3rem;
	padding-bottom: 3rem;
	display: flex;
}

.container-6.footer.gdrp {
	flex-direction: row;
	padding-top: 2rem;
	padding-bottom: 2rem;
	position: relative;
}

.home-content-footer-2 {
	z-index: 5000;
	background-image: linear-gradient(rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72)), url('/20181016082423/assets/images/pattern-2-04-min.png');
	background-position: 0 0, 50%;
	background-repeat: repeat, repeat-x;
	background-size: auto, 1500px;
	background-attachment: scroll, scroll;
	border: 1px #000;
	position: static;
}

.gdrp-section-icon {
	z-index: 1000000000;
	min-height: 100px;
	background-color: #fff;
	border-top: 4px #d3d3d3;
	justify-content: center;
	display: block;
	position: fixed;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
	box-shadow: 0 -13px rgba(179, 182, 183, 0.75);
}

.gdrp-section-icon.just-icon {
	z-index: 100000000;
	min-height: 120px;
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	display: flex;
	top: auto;
	bottom: 0%;
	left: auto;
	right: 3%;
}

.open-gdrp-icon-2 {
	width: 50px;
	height: 50px;
	border: 0.5px solid var(--4);
	background-color: var(--slate-grey);
	color: var(--orange);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	border-radius: 50px;
	margin-right: 1.9rem;
	padding-right: 0;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open-gdrp-icon-2:hover {
	transform: rotate(35deg) scale(1.1);
}

.gdrp-link-copy-2 {
	color: #fff;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	margin-right: 20px;
	padding: 10px;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	display: flex;
}

.gdrp-link-copy-2:hover {
	color: #fff;
	text-decoration: underline;
}

.image-32 {
	margin-top: -15px;
	margin-left: 10px;
	padding-top: 0;
}

.gdrp-link-2 {
	background-color: var(--orange);
	color: #fff;
	text-align: center;
	border-radius: 10px;
	margin-right: 20px;
	padding: 5px 10px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
	text-decoration: none;
	display: flex;
}

.gdrp-link-2:hover {
	color: #fff;
	text-decoration: underline;
}

.gdrp-section-2 {
	z-index: 1000000000;
	min-height: 100px;
	background-color: var(--slate-grey);
	border-top: 4px #d3d3d3;
	justify-content: center;
	display: none;
	position: fixed;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
	box-shadow: 0 -5px #1d1d1b;
}

.button-link-2 {
	color: #fff;
	text-transform: uppercase;
	white-space: normal;
	cursor: pointer;
	border-bottom: 4px #000;
	margin-top: 40px;
	padding-top: 0;
	font-family: Open Sans, sans-serif;
	font-size: 13px;
	font-weight: 700;
	text-decoration: none;
}

.button-link-2.cookies {
	margin-top: 20px;
}

.cookie-div {
	width: 33.33%;
	min-width: 425px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.exit-icon-div {
	width: 25px;
	height: 25px;
	opacity: 0.65;
	background-image: url('/20181016082423/assets/images/icon-rounded-close-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	border-bottom-style: none;
	text-decoration: none;
	transition-property: none;
	position: absolute;
	top: 1rem;
	bottom: auto;
	left: auto;
	right: 1rem;
}

.exit-icon-div:hover {
	opacity: 1;
}

.cookie-text-para-2 {
	width: 66.66%;
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 0.8rem;
	line-height: 1.3rem;
}

.button-underline {
	height: auto;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: -20px;
	transition: transform 0.4s;
	display: flex;
	transform: translate(0);
}

.button-underline:hover {
	transform: scale(1.1);
}

.button-underline.gdpr {
	display: none;
}

.socials {
	flex-direction: column;
	display: flex;
}

.product-slide-content-wrapper {
	height: 100%;
	background-color: #fff;
	border-radius: 5px;
	flex-direction: column;
	justify-content: center;
	display: flex;
	overflow: hidden;
}

.product-slide-content-wrapper:hover {
	overflow: hidden;
	box-shadow: 0 0 13px rgba(0, 0, 0, 0.23);
}

.product-slide-content-wrapper.last {
	background-color: rgba(0, 0, 0, 0);
}

.product-slide-content-wrapper.max._2 {
	background-color: rgba(255, 255, 255, 0);
	border-style: none;
}

.product-slide-content-wrapper.max._2:hover {
	box-shadow: none;
	overflow: visible;
}

.product-slide-content-wrapper.single {
	display: none;
}

.slide-product {
	width: 20%;
	height: 100%;
	padding: 16px 13px;
	overflow: visible;
}

.slidermask {
	height: 500px;
	margin-top: 10px;
}

.blog-link {
	float: left;
	clear: both;
	color: var(--orange);
	text-align: left;
	text-transform: capitalize;
	background-image: url('/20181016082423/assets/images/arrow-right-orange_1.svg');
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: 20px;
	justify-content: flex-start;
	margin-left: 10px;
	padding-top: 14px;
	padding-bottom: 14px;
	padding-right: 1.6rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5rem;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: flex;
}

.blog-link:hover {
	padding-right: 2rem;
}

.text-block-47,
.text-block-48 {
	font-size: 16px;
}

.slide-promo-item {
	width: 20%;
	padding: 16px 13px;
	overflow: visible;
}

.slidermask-promo-items {
	height: 420px;
	overflow: hidden;
}

.categories-slider-widget {
	width: 100%;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	display: flex;
}

.categories-slider-widget.mob {
	display: none;
}

.icon-7 {
	font-size: 32px;
}

.brand {
	width: 250px;
	height: 105px;
	float: left;
	align-items: center;
	margin-right: 1rem;
	transition: all 0.3s;
	display: flex;
}

.brand.w--current {
	width: 250px;
	margin-right: 2rem;
}

.dropdown-link {
	color: #fff;
	margin-top: 2px;
	margin-bottom: 2px;
	padding: 0.5rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.3rem;
	text-decoration: none;
	transition: all 0.2s;
	display: block;
}

.dropdown-link:hover {
	color: #0a706b;
	background-color: #fff;
	font-weight: 400;
}

.dropdown-link.w--current {
	color: #0a706b;
	background-color: #fff;
}

.mob-menu {
	float: right;
	margin-left: 2rem;
	display: none;
}

.dropdown-wrapper {
	text-align: center;
	flex-shrink: 0;
	overflow: hidden;
}

.navbar {
	background-color: rgba(0, 0, 0, 0);
	padding-left: 3rem;
	padding-right: 3rem;
}

.dropdown-list {
	height: 0;
	max-width: 200px;
	min-width: 200px;
	opacity: 0;
	text-align: left;
	background-color: #0a706b;
	margin-left: 0.75rem;
	padding: 0.5rem;
	position: absolute;
	overflow: hidden;
}

.container {
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.container.nav-container {
	float: none;
	justify-content: center;
	margin-top: 40px;
	display: flex;
}

.main-nav-link {
	color: #2d3047;
	text-transform: uppercase;
	margin-left: 2px;
	margin-right: 2px;
	padding: 0.6rem 0.75rem;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.7rem;
	text-decoration: none;
	transition: color 0.3s;
	display: block;
	position: relative;
}

.main-nav-link:hover,
.main-nav-link.w--current {
	color: #0a706b;
	box-shadow: inset 0 3px #0a706b;
}

.inside-page-header-container {
	width: 100%;
	max-width: 1550px;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.heading-5-copy {
	color: var(--slate-grey);
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	line-height: 42px;
}

.inside-page-title-banner {
	flex-direction: column;
	justify-content: center;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
}

.h6-header-link {
	border-bottom: 2px solid var(--orange);
	color: var(--slate-grey);
	text-transform: capitalize;
	margin-bottom: 1rem;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h6-header-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.breadcrumb-link-2 {
	color: #00244b;
	text-transform: capitalize;
	text-decoration: none;
	transition: all 0.2s;
}

.breadcrumb-link-2:hover {
	color: #ff5f00;
}

.breadcrumb-link-2.w--current {
	color: #c66b0e;
}

.breadcrumb-link-2.current-breadcrumb {
	color: #00244b;
	font-weight: 700;
}

.block-quote {
	background-color: var(--8);
	color: var(--slate-grey);
	text-align: center;
	border-left-style: none;
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 30px;
	line-height: 1.75rem;
}

.text-link {
	border-style: none none solid;
	border-width: 0 0 2px;
	border-color: transparent transparent var(--orange);
	color: var(--slate-grey);
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
	transition: all 0.2s;
}

.breadcrumbs-list-item-2 {
	font-size: 14px;
	display: inline-block;
}

.inside-page-container {
	width: 100%;
	max-width: 1300px;
	flex-direction: row;
	justify-content: flex-end;
	display: flex;
}

.inside-page-container.white-background {
	width: 100%;
	background-color: #fff;
	align-self: center;
	padding: 1rem 16px 2rem;
}

.inside-page-container.white-background.left-nav {
	max-width: 1550px;
	flex-direction: column;
	padding-bottom: 6rem;
	padding-left: 0;
	padding-right: 0;
}

.h3-header-link {
	border-bottom: 2px solid var(--orange);
	color: var(--slate-grey);
	margin-bottom: 1rem;
	font-size: 28px;
	font-weight: 700;
	line-height: 30px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h3-header-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.content-container {
	width: 75%;
	max-width: none;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: #fff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: grid;
}

.content-container.full {
	width: 70%;
	max-width: 1000px;
	background-color: rgba(255, 255, 255, 0);
	flex-direction: column;
	display: flex;
	margin-top: -16px;
	padding-right: 1rem;
}

.no-columns .content-container.full {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.content-container.full.lvl-2-wrap {
	grid-column-gap: 64px;
	grid-row-gap: 64px;
}

.qt-data-row-text {
	min-width: 10%;
	text-align: center;
	font-size: 0.85rem;
	display: inline-block;
}

.qt-data-row-text.first-col-date {
	text-align: left;
	text-transform: capitalize;
	font-size: 16px;
}

.righ-menu {
	background-color: var(--slate-grey);
	color: #fff;
	text-transform: uppercase;
	padding: 10px 2rem;
	font-weight: 700;
	line-height: 1.2rem;
}

.left-nav-list {
	background-color: var(--4);
	color: var(--slate-grey);
	border-radius: 0 0 5px 5px;
	margin-top: 0;
	margin-bottom: 2rem;
}

.sidebar-list-item {
	color: var(--slate-grey);
	margin-bottom: 0.75em;
	padding-left: 0;
}

.left-nav-list-link-nested {
	color: var(--slate-grey);
	text-transform: none;
	padding: 0.5rem 0.75rem 0.5rem 3rem;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.25rem;
	text-decoration: none;
	transition: all 0.2s;
	display: block;
}

ul ul ul li .left-nav-list-link-nested {
	padding: 0.5rem 0.75rem 0.5rem 4rem;
}

.left-nav-list-link-nested:hover {
	color: #00244b;
	text-decoration: underline;
	box-shadow: 3px 0 #ff5f00;
}

.intro-paragraph {
	color: #00244b;
	margin-bottom: 1rem;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.75rem;
}

.breadcrumbs-list-2 {
	max-width: 1300px;
	color: rgba(255, 255, 255, 0.75);
	margin-bottom: 28px;
	padding-top: 18px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	line-height: 1.2rem;
}

.sidebar-list {
	padding-left: 0;
}

.paragraph {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.paragraph.list-item {
	margin-bottom: 0;
}

.rightnav-holder {
	width: 25%;
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	margin-top: 0;
	margin-left: 1rem;
	margin-right: 0;
}

.leftnav-holder {
	width: 30%;
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	margin-right: 2rem;
}

.left-nav-nested-list {
	border-top: 1px solid var(--30);
	background-color: #f1f1f1;
	margin-bottom: 0;
	padding-top: 0.375rem;
	padding-bottom: 0;
	padding-left: 0;
}

.leftnavigation {
	width: 100%;
	flex-direction: column;
	align-items: center;
	margin-top: 0;
	padding-top: 0;
	display: flex;
}

.rightnavigationblock {
	color: var(--slate-grey);
	background-color: rgba(255, 128, 0, 0.15);
	border-radius: 5px;
	padding: 2rem;
	font-size: 16px;
}

.sidebar-section-title {
	text-transform: none;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-bottom: 0.75rem;
	padding-left: 0;
	line-height: 1.775rem;
}

.ordered-list {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.text-block-14 {
	color: #00244b;
}

.left-nav-list-link {
	color: var(--slate-grey);
	text-transform: none;
	background-color: rgba(0, 0, 0, 0);
	padding: 0.8rem 2rem;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.25rem;
	text-decoration: none;
	transition: all 0.2s;
	display: block;
}

.left-nav-list-link:hover {
	background-color: var(--8);
}

.left-nav-list-link.w--current {
	color: #474d47;
	background-color: rgba(0, 0, 0, 0.01);
	box-shadow: inset 3px 0 #42a644;
}

.left-nav-nested-list-item {
	color: #00244b;
}

.qt-accordion-item-content-wrap {
	width: 100%;
	height: auto;
	min-height: none;
	float: left;
	background-color: var(--4);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding-left: 0;
	padding-right: 0;
	font-size: 0.875rem;
	line-height: 1.375em;
	overflow: hidden;
	box-shadow: 0 1px 20px -14px rgba(0, 0, 0, 0.2);
}

.h4 {
	font-size: 24px;
}

.unordered-list {
	margin-bottom: 1rem;
}

.h5-header-link {
	border-bottom: 2px solid var(--orange);
	color: var(--slate-grey);
	text-transform: none;
	margin-bottom: 1rem;
	font-size: 18px;
	font-weight: 700;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.btn-container {
	grid-column-gap: 16px;
	align-items: center;
	display: flex;
}

.image-9 {
	width: 100%;
	margin-bottom: 20px;
}

.left-nav-list-item {
	margin-bottom: 1px;
}

.h6 {
	text-transform: none;
	font-size: 16px;
}

.inside-page-content {
	width: 100%;
	background-image: none;
	background-position: 0 0;
	background-size: auto;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding: 0 6rem;
	display: flex;
	box-shadow: 0 -1px 14px -11px #000;
}

.inside-page-content.left---right {
	box-shadow: none;
	background-image: none;
	background-size: auto;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 1rem;
	font-family: Roboto, sans-serif;
}

.h4-header-link {
	border-bottom: 2px solid var(--orange);
	color: var(--slate-grey);
	margin-bottom: 1rem;
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h4-header-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.heading-12 {
	margin-top: 40px;
}

.main-content {
	width: 100%;
	display: flex;
}

.main-content.lvl1 {
	margin-top: 40px;
}

.main-content.product-page {
	grid-column-gap: 48px;
	grid-row-gap: 48px;
	border-radius: 5px;
	justify-content: flex-start;
	margin-bottom: 3rem;
}

.h2-header-link {
	border-bottom: 2px solid var(--orange);
	color: var(--slate-grey);
	margin-bottom: 1rem;
	font-size: 32px;
	font-weight: 700;
	line-height: 2.25rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h3 {
	font-size: 28px;
}

.h5 {
	text-transform: none;
	font-size: 18px;
}

.h5.paddings {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 700;
}

.sidebar-section-wrap {
	color: var(--slate-grey);
	border-top: 1px rgba(0, 0, 0, 0.15);
	margin-bottom: 2.441em;
	margin-left: 0;
	line-height: 1.5em;
	position: relative;
}

.qt-data-rows-wrap {
	border-radius: 5px;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 16px;
	line-height: 1.25em;
	transition: all 0.2s;
}

.secondary-button {
	z-index: 5;
	border: 1px none var(--slate-grey);
	outline-color: var(--slate-grey);
	outline-offset: -1px;
	color: var(--slate-grey);
	text-transform: capitalize;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 5px;
	outline-width: 1px;
	outline-style: solid;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	position: relative;
}

.secondary-button:hover {
	color: var(--4);
	background-color: #1c2b3c;
	transform: translate(0, -3px);
}

.table-style-embed {
	color: var(--orange);
	border-radius: 5px;
	font-size: 16px;
	overflow: hidden;
}

.main-nav-link-icon {
	color: #2d3047;
	text-transform: uppercase;
	margin-left: 2px;
	margin-right: 2px;
	padding: 0.6rem 0.75rem;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.7rem;
	text-decoration: none;
	transition: color 0.3s;
	display: block;
	position: relative;
}

.main-nav-link-icon:hover,
.main-nav-link-icon.w--current {
	color: #0a706b;
	box-shadow: inset 0 3px #0a706b;
}

.faq-item {
	width: 100%;
	max-width: none;
	background-color: var(--4);
	color: var(--slate-grey);
	border-radius: 0;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.faq-question {
	width: 100%;
	border-top: 1px solid var(--8);
	color: #000;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	display: flex;
}

.faq-question:hover {
	color: var(--slate-grey);
}

.faq-question.first {
	border: 1px #000;
}

.p-m-wrap {
	width: 15px;
	height: 15px;
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.faq-answer {
	width: 100%;
	text-align: left;
	border-bottom: 1px #cfcfcf;
	overflow: hidden;
}

.filter-ui {
	width: 100%;
	height: auto;
	float: left;
	background-color: var(--4);
	border-radius: 5px;
	padding-left: 0;
	padding-right: 0;
	font-size: 0.875rem;
	line-height: 1.375em;
	overflow: hidden;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.ui-title {
	background-color: var(--slate-grey);
	color: #fff;
	text-transform: uppercase;
	padding: 10px;
	font-weight: 700;
}

.fs-style_dropdown-icon {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 0;
}

.text-block-49 {
	font-weight: 500;
}

.price {
	width: 100%;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
}

.text-field {
	border-radius: 5px;
}

.text-field:focus {
	border: 1px solid var(--orange);
}

.text-field-2 {
	border-radius: 5px;
}

.text-field-2:focus {
	border: 1px solid var(--orange);
}

.bands-checks {
	grid-column-gap: 6px;
	grid-row-gap: 6px;
	flex-direction: column;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 1rem;
	display: flex;
}

.checkbox {
	width: 13px;
	height: 13px;
	border-color: var(--slate-grey);
	background-color: #fff;
}

.checkbox:active {
	width: 13px;
	height: 13px;
	padding: 1px;
}

.checkbox.w--redirected-checked {
	border-color: var(--orange);
	background-color: var(--orange);
}

.checkbox.w--redirected-focus {
	box-shadow: none;
}

.dropdown-4 {
	justify-content: flex-end;
	align-self: flex-end;
	margin-left: 0;
	margin-right: 0;
	display: flex;
}

.dropdown-list-2 {
	margin-top: -19px;
}

.dropdown-list-2.w--open {
	background-color: var(--4);
	border-radius: 5px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 35px;
	display: flex;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.price-sort {
	width: 100%;
	justify-content: center;
	align-self: center;
	display: flex;
}

.price-sort:hover {
	background-color: var(--8);
}

.div-block-121 {
	justify-content: space-between;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
}

.reset-button {
	color: var(--slate-grey);
	font-size: 1rem;
	font-weight: 500;
	text-decoration: underline;
}

.reset-button:hover {
	text-decoration: none;
}

.form-block {
	flex-direction: column;
}

.product-row {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: space-between;
	display: flex;
}

.category-card {
	width: 60%;
	background-color: #fff;
	margin-left: auto;
	margin-right: auto;
	padding: 20px 40px 20px 125px;
	margin-bottom: 60px;
	opacity: 0;
	transition: opacity 600ms ease, transform 500ms ease;
	transform: translate3d(0px, 100px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.product-cat-lvl1-container .category-card.active {
	opacity: 1;
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.category-card a {
	text-decoration: none !important;
}

.cat-2-title-img-wraper {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
	display: flex;
}

.lvl2-cat-img-circle {
	width: 100%;
	height: 100%;
	max-height: 80px;
	max-width: 80px;
	min-height: 80px;
	min-width: 80px;
	outline-color: var(--30);
	outline-offset: 0px;
	object-fit: fill;
	background-image: url('/20181016082423/assets/images/6413.jpg'), linear-gradient(#fff, #fff);
	background-position: 50%, 0 0;
	background-repeat: no-repeat, repeat;
	background-size: contain, auto;
	border-radius: 100px;
	outline-width: 1px;
	outline-style: solid;
}

.body-2,
.body-3 {
	background-color: var(--4);
}

.see-more-button {
	justify-content: flex-end;
	padding-top: 20px;
	display: flex;
}

.text-block-50 {
	font-size: 16px;
	font-weight: 400;
}

.dropdown-toggle-2 {
	padding-top: 0;
	padding-bottom: 0;
}

.lvl3-cat-name-wrapper {
	grid-column-gap: 32px;
	justify-content: flex-start;
	display: flex;
	flex-wrap: wrap;
}

.cat-title-box {
	min-width: 45%;
	border-bottom: 1px solid var(--8);
	padding-top: 10px;
	padding-bottom: 10px;
}

.lvl3-cat-link {
	color: var(--slate-grey);
	margin-bottom: 0;
	font-size: 16px;
}

.lvl3-cat-link:hover {
	text-decoration: underline;
}

.div-block-122 {
	margin-left: auto;
	margin-right: auto;
}

.lvl-3-cat-name-carousel-wrap {
	width: 100%;
	max-width: 1550px;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 32px;
	padding-bottom: 32px;
}

.slider-4 {
	width: 100%;
	background-color: #fff;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;
	display: flex;
}

.slide-sm {
	width: 12.5%;
	cursor: pointer;
	padding: 15px;
}

.cat-3-title-img-wraper {
	height: 100%;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
}

.slide-nav-4 {
	display: none;
}

.lvl3-cat-img-circle {
	width: 75px;
	height: 75px;
	outline-color: var(--30);
	outline-offset: 0px;
	object-fit: fill;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 100%;
	border-radius: 100px;
	outline-width: 1px;
	outline-style: solid;
	margin-top: 5px;
}

.mask {
	width: 100%;
}

.carousel-wrapper {
	width: 100%;
	background-color: #fff;
}

.pagination-wrap {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
	display: none;
}

.icon-10-copy {
	transform: rotate(180deg);
}

.pagination {
	width: 50px;
	height: 50px;
	color: var(--60);
	cursor: pointer;
	background-color: #fff;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	font-size: 1.25rem;
	font-weight: 700;
	transition: all 0.3s;
	display: flex;
	top: -10%;
	bottom: auto;
	left: auto;
	right: 5rem;
}

.pagination:hover {
	background-color: var(--orange);
	color: var(--4);
}

.pagination.number {
	color: var(--slate-grey);
	background-color: rgba(255, 255, 255, 0);
}

.pagination.number:hover {
	background-color: var(--orange);
	color: var(--4);
}

.pagination.number.selected {
	background-color: var(--slate-grey);
	color: var(--4);
}

.heading-14 {
	margin-bottom: 0;
}

.bottom-paragraph {
	margin-top: 40px;
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.lvl-1-title-img-wraper {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	cursor: pointer;
	background-color: #fff;
	border-radius: 5px;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 16px;
	transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: flex;
}

.lvl-1-title-img-wraper:hover {
	transform: translate(0, -5px);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.brand-category-list {
	width: 100%;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto auto auto auto auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	padding-bottom: 20px;
	display: grid;
}

.paragraph-4 {
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 500;
}

.product-img-grid {
	width: 55%;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1.15fr 1.15fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.product-detail-wrap {
	width: 35%;
}

.title-share-icon {
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	display: flex;
}

.div-block-123 {
	width: 28px;
	height: 25px;
	cursor: pointer;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.text-span {
	cursor: pointer;
	text-decoration: underline;
}

.quantity-wrapper-3 {
	width: 90px;
	outline-color: var(--30);
	outline-offset: 0px;
	color: #1c2b3c;
	border-radius: 5px;
	outline-width: 1px;
	outline-style: solid;
	justify-content: space-around;
	align-items: center;
	display: flex;
}

.text-block-52 {
	color: #1c2b3c;
	line-height: 1.5rem;
}

.text-block-52:hover {
	transform: scale(1.2);
}

.text-block-52.text-quantity {
	color: var(--slate-grey);
}

.text-block-52.text-quantity:hover {
	transform: none;
}

.link-block-5 {
	color: #1c2b3c;
	text-decoration: none;
	transition: all 0.25s;
}

.link-block-5:hover {
	transform: scale(1.2);
}

.primary-btn-3 {
	z-index: 5;
	width: 100%;
	max-width: 185px;
	text-transform: uppercase;
	background-color: #ff8000;
	border-radius: 5px;
	justify-content: center;
	align-self: center;
	align-items: center;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	display: flex;
	position: relative;
}

.primary-btn-3:hover {
	background-color: #1c2b3c;
	transform: translate(0, -3px);
}

.link-block-6 {
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: all 0.25s;
	display: flex;
}

.link-block-6:hover {
	transform: scale(1.2);
}

.secure-payments-wrap {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: #fff;
	border-radius: 5px;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 16px;
	padding: 32px;
	display: flex;
}

.paragraph-5 {
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 500;
}

.security {
	width: 30px;
	height: 30px;
	background-image: url('/20181016082423/assets/images/lock-alt-1c2b3c.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.security.ppal {
	background-image: url('/20181016082423/assets/images/paypal-svgrepo-com.svg');
}

.security.mastercard {
	background-image: url('/20181016082423/assets/images/mastercard-svgrepo-com.svg');
}

.security.visa {
	background-image: url('/20181016082423/assets/images/visa-svgrepo-com.svg');
}

.div-block-124 {
	flex-direction: row;
	align-items: center;
	display: flex;
}

.payments-wrap {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	display: flex;
}

.additional-product-content {
	width: 65%;
	background-color: #fff;
	border-radius: 5px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	padding: 32px;
	display: flex;
}

.img-dummy {
	width: 100%;
	height: 100%;
	background-image: url('/20181016082423/assets/images/DUMMY-IMG-square.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.paragraph-6 {
	margin-bottom: 16px;
	font-size: 16px;
	line-height: 22px;
}

.variations-dropdown {
	width: 100%;
	display: block;
}

.dropdown-toggle-3 {
	border: 1px solid var(--30);
	background-color: #fff;
	border-radius: 5px;
	justify-content: flex-start;
	align-items: center;
	padding-top: 7px;
	padding-bottom: 7px;
	display: flex;
}

.div-block-125 {
	height: 16px;
	border-left: 2px solid var(--orange);
	align-self: center;
	align-items: center;
	margin-left: 20px;
	padding-left: 20px;
	padding-right: 40px;
	display: flex;
}

.heading-16 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16px;
}

.variation-link {
	background-color: var(--4);
}

.variation-link:hover {
	background-color: var(--8);
}

.variations-wrap {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-direction: column;
	display: flex;
}

.mobile-img-product-slider {
	display: none;
}

.dropdown-toggle-4 {
	background-color: #fff;
	border-radius: 100px;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	padding: 5px 30px 5px 20px;
	display: flex;
}

.quick-filter {
	cursor: pointer;
	justify-content: flex-end;
	margin-bottom: 16px;
	display: flex;
}

.quick-filter.alt {
	display: none;
}

.center-div {
	align-items: center;
	display: flex;
}

.pagination-div {
	width: 100%;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	flex-direction: row;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
	display: flex;
}

.pagination-block {
	float: right;
	order: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.filter-products-link {
	float: none;
	color: #333;
	margin-left: 0;
	font-size: 1rem;
	text-decoration: none;
	transition: all 0.45s;
	display: flex;
}

.filter-products-link:hover {
	border-bottom-color: #bebebe;
}

.pagination-form-wrapper {
	margin-bottom: 0;
}

.pagination-form {
	align-items: center;
	font-size: 12px;
	display: flex;
}

.previous-div {
	order: -1;
}

.next-div {
	order: 1;
}

.page-block {
	display: flex;
}

.page {
	align-self: center;
	font-size: 16px;
	display: inline-block;
}

.numbers-drop-down {
	justify-content: flex-end;
	align-self: flex-end;
	margin-left: 0;
	margin-right: 0;
	display: flex;
}

.icon-11 {
	margin-right: 10px;
}

.pages {
	width: 100%;
	justify-content: center;
	align-self: center;
	margin-left: 0;
	margin-right: 0;
	font-size: 16px;
	display: flex;
}

.pages:hover {
	background-color: var(--8);
}

.text-block-53 {
	padding-right: 10px;
}

.dropdown-toggle-4-copy {
	background-color: #fff;
	border-radius: 100px;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	padding: 5px 30px 5px 10px;
	display: flex;
}

.text-block-54 {
	font-size: 16px;
}

.filter-ui-mobile {
	width: 100%;
	height: auto;
	float: left;
	background-color: var(--4);
	border-radius: 5px;
	padding-left: 0;
	padding-right: 0;
	font-size: 0.875rem;
	line-height: 1.375em;
	display: none;
	overflow: hidden;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.heading-18 {
	font-size: 16px;
}

.paragraph-5-copy {
	margin-bottom: 0;
	font-size: 20px;
	font-weight: 700;
}

.main-product-details {
	background-color: #fff;
	border-radius: 5px;
	padding: 32px;
}

.heart-_-l {
	width: 40px;
	background-image: url('/20181016082423/assets/images/heart.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 27px 30px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 16px;
	padding-left: 0;
	transition: all 0.2s;
	display: flex;
}

.heart-_-l:hover {
	background-image: url('/20181016082423/assets/images/heart---orange-full.svg');
}

.divider {
	width: 15%;
	height: 1px;
	background-color: var(--30);
}

.divider.vertical {
	width: 1px;
	height: 16px;
	margin-left: 16px;
	margin-right: 16px;
}

.div-block-126 {
	align-items: center;
	display: flex;
}

.text-block-55,
.paragraph-7,
.text-block-56 {
	font-size: 16px;
}

.checkbox-field {
	font-size: 1rem;
}

.static-slider {
	width: 90%;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.deliveryicon {
	width: 26px;
	height: 26px;
	min-height: 26px;
	min-width: 26px;
	background-image: url('/20181016082423/assets/images/delivery-solid-1c2b3c.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	display: none;
}

.delivery-wrap {
	grid-column-gap: 5px;
	grid-row-gap: 5px;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	display: flex;
}

.available-wrap {
	grid-column-gap: 6px;
	grid-row-gap: 6px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.green-dot {
	width: 16px;
	height: 16px;
	background-color: var(--green);
	border-radius: 20px;
}

.red-dot {
	width: 16px;
	height: 16px;
	background-color: var(--burgundy);
	border-radius: 20px;
}

.div-block-128 {
	z-index: 100;
	width: 100%;
	margin-left: auto;
	padding-top: 10px;
	padding-right: 10px;
	position: relative;
}

.product-img {
	width: 90%;
	max-height: 210px;
	margin-left: auto;
	margin-right: auto;
	transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.product-img:hover {
	opacity: 0.85;
}

._6pcs {
	width: 100%;
	display: flex;
}

._4pcs {
	width: 100%;
	display: none;
}

.excerpt {
	width: 100%;
	line-height: 22px;
}

.titles-headings-copy-copy {
	color: var(--slate-grey);
	text-align: center;
	text-transform: capitalize;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 0;
	font-size: 38px;
	line-height: 40px;
	display: inline-block;
}

.extrawrapperdelivery {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	align-items: center;
	display: flex;
}

@media screen and (min-width: 1440px) {
	.tab-link-all-2.menu:hover {
		background-color: #f6f7f7;
	}

	.drop-down-list-2.w--open {
		top: 70px;
	}

	.drop-down-list-2.blog.w--open {
		justify-content: space-between;
	}
}

@media screen and (max-width: 991px) {
	.brix---header-logo {
		max-width: 232px;
	}

	.inside-page-title-banner {
		padding-left: 32px;
		padding-right: 32px;
	}

	.content-container.full {
		width: 100%;
		max-width: 100%;
	}

	.brix---top-bar-close-icon {
		padding: 5px 24px;
	}

	.brix---top-bar-right-col {
		margin-right: 0;
	}

	.brix---top-bar-flex-h-center {
		line-height: 20px;
		display: flex;
	}

	.brix---top-bar-wrapper {
		align-items: center;
		padding-left: 56px;
		padding-right: 56px;
	}

	.cf-visual-features-item.navbar {
		padding: 25px;
	}

	.cf-navigation-item-heading {
		font-size: 20px;
	}

	.cf-visual-features-items-container {
		max-width: 500px;
		grid-row-gap: 40px;
		grid-template-columns: 1fr;
		margin-left: auto;
		margin-right: auto;
	}

	.cf-visual-features-items-container.nav {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.cf-visual-features-items-container.nav.blogs {
		grid-template-columns: 1fr 1fr;
	}

	.cf-visual-features-items-container.nav {
		width: 100%;
		max-width: none;
		flex-direction: column;
		justify-content: flex-start;
		margin-left: 0;
		display: flex;
	}

	.cf-navigation-drop-container-2 {
		flex-direction: column;
	}

	.cf-dropdown-arrow---nav-3 {
		margin-left: 7px;
		margin-right: 0;
		position: relative;
	}

	.cf-dropdown-nav-3 {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		display: none;
	}

	.cf-growth-wrapper {
		padding: 40px 30px;
	}

	.property-spotlight-image-link-3 {
		display: block;
	}

	.property-spotlight-image-link-3:hover {
		border-bottom-color: #dadada;
	}

	.property-spotlight-image-link-3.product2 {
		height: 100%;
		max-height: 175px;
		min-height: 150px;
		padding-top: 0%;
		padding-bottom: 0%;
	}

	.listing-details {
		line-height: 1.2rem;
	}

	.icon-5 {
		color: #8b8b8b;
		font-size: 2.15rem;
	}

	.widget-outer-warp {
		margin-left: auto;
		margin-right: auto;
	}

	.cart-wrapper {
		align-items: center;
	}

	.prop-spotlight-details-wrapper-3 {
		padding-bottom: 0;
	}

	.product-details {
		margin-bottom: 10px;
	}

	.refined-widget {
		height: 400px;
		margin-top: 105px;
		top: -130px;
	}

	.refined-widget.mob {
		display: block;
	}

	.refined-widget.mob.categories {
		margin-top: 35px;
		top: 0;
	}

	.title-container {
		align-self: flex-start;
		align-items: flex-start;
		margin-left: 2%;
	}

	.tab-link-all {
		background-color: #fff;
	}

	.tab-link {
		width: 100%;
		max-width: 26ch;
		text-align: center;
	}

	.tabs-menu {
		grid-column-gap: 10px;
		justify-content: flex-start;
		margin-bottom: 0;
		padding-left: 2%;
		padding-right: 2%;
	}

	.uui-navbar02_blog-content {
		max-width: none;
	}

	.uui-navbar02_dropdown-content-button-wrapper {
		margin-bottom: 0;
		margin-left: 0;
	}

	.main_container__social-media-container {
		grid-column-gap: 16px;
	}

	.social-media-link__social-media-image {
		width: 5.2em;
		height: 5em;
		margin-bottom: 0;
	}

	.social-media-link__social-media-image.small {
		width: 5em;
		height: 5em;
	}

	.signup {
		padding-top: 0;
		padding-bottom: 40px;
	}

	.footer-small-text {
		line-height: 18px;
	}

	.footer-small-text.text-color-gray500 {
		color: var(--8);
	}

	.footer_bottom-wrapper {
		flex-direction: column-reverse;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.uui-logo_logotype {
		margin-left: 0;
	}

	.footer_top-wrapper {
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		grid-template-columns: 2fr 2fr 1fr 1fr;
	}

	.uui-footer05_form-block {
		min-width: auto;
	}

	.uui-footer05_newsletter-text-wrapper {
		margin-right: 16px;
	}

	.uui-footer05_newsletter-wrapper {
		width: 100%;
		grid-column-gap: 16px;
		justify-content: center;
		margin-left: 5%;
		margin-right: 5%;
	}

	.footer-padding-vertical {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}

	.footer-grip-link.white-logo {
		margin-bottom: 1rem;
	}

	.grip-logo-text-box-2.webdevby-white {
		text-align: right;
	}

	.tabs-content {
		top: 30px;
	}

	.category-link {
		width: 20%;
		text-align: center;
		flex-wrap: nowrap;
		margin-left: 5px;
		margin-right: 5px;
		padding-left: 0;
		position: static;
	}

	.category-link.hide {
		display: none;
	}

	.category-text {
		text-align: center;
		letter-spacing: 0.7px;
		text-transform: uppercase;
		margin-top: 16px;
		line-height: 1.1rem;
	}

	.dropdown-toggle {
		padding-right: 1.5rem;
		top: -15px;
	}

	.language-dropdown-list.w--open {
		right: 0;
	}

	.language-arrow-icon {
		margin-right: 0.25rem;
	}

	.titles-headings-copy {
		text-align: left;
		font-size: 30px;
		line-height: 34px;
	}

	.nav-menu {
		justify-content: center;
		align-items: center;
		display: none;
	}

	.left-arrow-2 {
		right: 10vw;
	}

	.was-wording,
	.is-now,
	.listing-details-copy,
	.listing-details-copy {
		line-height: 1.2rem;
	}

	.orange-price {
		padding-bottom: 0.25rem;
		line-height: 1.5rem;
	}

	.cf-dropdown-list-full-wrapper-2-copy {
		box-shadow: none;
		padding: 0;
	}

	.tab-change_tab-content {
		right: 0;
	}

	.uui-navbar02_dropdown-link-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-navbar02_dropdown-link-2:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.tab-change_style-tab {
		flex-direction: column;
		margin-left: 0;
		margin-right: 0;
	}

	.uui-navbar02_dropdown-content-3 {
		flex-direction: column;
	}

	.cf-dropdown-toggle---nav-3 {
		justify-content: flex-start;
		margin-left: 5%;
		margin-right: 5%;
		padding-left: 0;
		display: flex;
	}

	.uui-navbar02_dropdown-content-left-3 {
		padding-left: 0;
		padding-right: 0;
	}

	.cf-dropdown-list--nav-3.w--open {
		width: 90%;
		min-width: auto;
		margin-left: 5%;
		margin-right: 5%;
		padding-top: 0;
		position: relative;
		top: 0;
	}

	.cf-dropdown-list-full-wrapper-3 {
		box-shadow: none;
		padding: 0;
	}

	.tab-change_tab-menu-2 {
		width: auto;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 0;
		margin-bottom: 24px;
		display: flex;
	}

	.last-item-on-carousel {
		justify-content: space-between;
	}

	.icon-5-copy {
		font-size: 2.15rem;
	}

	.hero-section {
		grid-template-rows: 2fr 1fr;
		grid-template-columns: 1fr 1fr;
		aspect-ratio: unset;
		height: 600px;
	}

	.categories-grid {
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-columns: auto auto auto auto;
	}

	.section-wrapper {
		padding-top: 60px;
		padding-bottom: 0;
	}

	.section-wrapper.vertical {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.section-wrapper.vertical.grey-bg {
		padding-top: 80px;
		padding-left: 3%;
		padding-right: 3%;
	}

	.section-wrapper.grey-bg {
		padding-top: 20px;
	}

	.section-wrapper.space-up {
		padding-bottom: 60px;
	}

	.secondary-title-wrap {
		align-self: flex-start;
		align-items: flex-start;
		margin-bottom: 0;
		padding-left: 3%;
	}

	.product-slider {
		flex: 0 auto;
		display: block;
	}

	.product-slider.tablet {
		padding-top: 10px;
		padding-bottom: 30px;
		overflow: hidden;
	}

	.product-slider.tablet.blog {
		padding-top: 70px;
		padding-bottom: 0;
		top: -80px;
	}

	.product-slider.brands {
		margin-top: -40px;
	}

	.blog-title {
		font-size: 18px;
		line-height: 22px;
	}

	.promo-paragraph {
		text-align: left;
	}

	.product-slider-arrow {
		width: 40px;
		height: 40px;
		box-shadow: none;
		border-width: 6px;
		border-radius: 4px;
		flex-wrap: wrap;
		justify-content: center;
		top: 1%;
	}

	.product-slider-arrow:hover {
		box-shadow: none;
	}

	.product-slider-arrow.property-arrow-right {
		box-shadow: none;
		flex-wrap: wrap;
		align-items: flex-end;
		margin-right: 10px;
		display: flex;
	}

	.product-slider-arrow.property-arrow-right:hover {
		box-shadow: none;
	}

	.product-slider-arrow.property-arrow-right.product {
		margin-right: 3%;
		bottom: 0;
		right: 0;
	}

	.product-slider-arrow.property-arrow-right.itm {
		background-color: var(--4);
		margin-bottom: 0;
		margin-right: 3%;
		top: -12%;
		bottom: 0;
		right: 0;
	}

	.product-slider-arrow.property-arrow-right.itm-copy {
		right: 0;
	}

	.product-slider-arrow.product {
		margin-top: 0;
		margin-right: 1rem;
		padding-right: 0;
		top: -26%;
		right: 3.5rem;
	}

	.product-slider-arrow.itm {
		background-color: var(--4);
		margin-top: -60px;
		margin-right: 1rem;
		top: -12%;
		right: 3.5rem;
	}

	.product-slider-arrow.itm:hover {
		background-color: var(--slate-grey);
		color: #fff;
	}

	.product-slider-arrow.blog {
		width: 45px;
		height: 45px;
	}

	.product-slider-arrow.blog.lvl2slider {
		width: 45px;
	}

	.product-slider-arrow.itm-copy,
	.product-slider-arrow.category {
		background-color: var(--4);
		margin-top: -60px;
		margin-right: 1rem;
		top: -12%;
		right: 3.5rem;
	}

	.product-slider-arrow.category:hover {
		background-color: var(--slate-grey);
		color: #fff;
	}

	.product-spotlight-slide {
		width: 33.33%;
		min-height: 125px;
		background-image: none;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		font-size: 1.25rem;
	}

	.product-spotlight-slide.brand {
		margin-top: 65px;
	}

	.product-spotlight-slide.blog {
		max-height: none;
		min-height: auto;
	}

	.icon {
		width: 40px;
		height: 40px;
		border-radius: 4px;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		display: flex;
		position: static;
	}

	.blog-container {
		max-width: none;
		overflow: hidden;
	}

	.product-spotlight-mask {
		flex-wrap: nowrap;
		margin-top: 0.5rem;
		padding-top: 20px;
		padding-bottom: 40px;
	}

	.product-spotlight-mask.blog {
		height: auto;
	}

	.phone-div {
		flex-direction: row;
		align-items: center;
		display: none;
	}

	.small-banner._1 {
		aspect-ratio: 506 / 272;
	}

	.small-banner._2 {
		aspect-ratio: 506 / 272;
	}

	.div-block {
		width: 100%;
		align-items: flex-start;
		position: relative;
	}

	.search-bar-2 {
		width: 100% !important;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		display: block;
		right: auto;
	}

	.search-input-3 {
		padding-top: 1.55rem;
	}

	.search-input-exit-2.two {
		padding-left: 0;
		padding-right: 0;
	}

	.x-close {
		width: 30px;
		height: 30px;
		background-color: var(--8);
		border-radius: 5px;
		justify-content: flex-end;
		padding: 5px;
		display: flex;
		position: relative;
		right: 0;
	}

	.vectors-wrapper-6 {
		width: 27px;
		height: 27px;
	}

	.cf-dropdown-list-full-wrapper-4 {
		box-shadow: none;
		padding: 0;
	}

	.uui-navbar02_dropdown-content-left-5 {
		padding-left: 0;
		padding-right: 0;
	}

	.search {
		margin-right: 10px;
	}

	.login {
		width: 27px;
		height: 27px;
		display: none;
	}

	.toggle-2 {
		justify-content: flex-start;
		margin-left: 5%;
		margin-right: 5%;
		padding-left: 0;
		display: flex;
	}

	.cf-dropdown-nav-5 {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		display: none;
	}

	.search-icon {
		width: 30px;
		height: 30px;
		min-height: auto;
		background-size: 17px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon:hover {
		background-size: 17px;
	}

	.header-content-wrapper-2 {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		grid-template-rows: auto;
		grid-template-columns: 0.25fr 1fr 1fr;
		justify-items: center;
	}

	.language-text-2 {
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.div-header-right-2 {
		margin-left: 0;
		margin-right: 10px;
	}

	.drop-down-list-2.w--open {
		width: 90%;
		min-width: auto;
		margin-left: 5%;
		margin-right: 5%;
		padding-top: 0;
		position: relative;
		top: 0;
	}

	.drop-down-list-2.blog.w--open {
		width: 90%;
	}

	.dropdown-3 {
		margin-left: 0;
		margin-right: -25px;
	}

	.language-div-2 {
		width: 25px;
		justify-content: center;
		margin-left: 0;
		display: none;
	}

	.uui-navbar02_dropdown-content-right-4 {
		max-width: 50rem;
		margin-bottom: 1rem;
	}

	.link-08-9229-8230 {
		display: none;
	}

	.ecommerce-icon-div-2 {
		width: 100%;
		justify-content: flex-end;
	}

	.link-2 {
		margin-top: 5px;
		padding-left: 0;
	}

	.link-2.mob {
		display: none;
	}

	.mob-phone-icon-2 {
		width: 27px;
		height: 27px;
		display: block;
	}

	.container-6.footer.gdrp {
		flex-direction: column;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.home-content-footer-2 {
		background-repeat: repeat, repeat-y;
	}

	.gdrp-section-icon.just-icon {
		width: 14%;
		right: 0%;
	}

	.open-gdrp-icon-2 {
		margin-right: 1.8rem;
	}

	.gdrp-link-copy-2,
	.gdrp-link-2 {
		margin-right: 1rem;
	}

	.button-link-2 {
		margin-top: 20px;
		font-size: 11px;
		position: static;
	}

	.cookie-div {
		width: 90%;
		justify-content: center;
	}

	.exit-icon-div {
		right: 1rem;
	}

	.cookie-text-para-2 {
		width: 90%;
		margin-bottom: 0.25rem;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.button-underline {
		margin-top: 5px;
		margin-left: -27px;
	}

	.product-slide-content-wrapper {
		display: flex;
	}

	.product-slide-content-wrapper.max._2,
	.product-slide-content-wrapper.hide,
	.product-slide-content-wrapper.single {
		display: none;
	}

	.slide-product {
		width: 25%;
		height: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}

	.slidermask {
		margin-top: 125px;
	}

	.slide-promo-item {
		width: 33.33%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.slidermask-promo-items {
		height: auto;
	}

	.categories-slider-widget {
		margin-top: 20px;
	}

	.categories-slider-widget.mob {
		display: none;
	}

	.icon-8 {
		font-size: 32px;
	}

	.brand,
	.brand.w--current {
		width: 225px;
	}

	.menu-button-text {
		text-align: center;
		margin-top: -8px;
		font-size: 0.7rem;
		line-height: 1rem;
	}

	.mobile-menu-icon {
		text-align: center;
		font-size: 2.5rem;
		line-height: 2.5rem;
	}

	.mob-menu {
		width: 100vw;
		background-color: var(--4);
		text-align: left;
		margin-top: 100px;
		margin-left: 0;
		display: block;
	}

	.dropdown-wrapper {
		display: none;
	}

	.navbar {
		padding-left: 2rem;
		padding-right: 1rem;
	}

	.container {
		max-width: 991px;
	}

	.container.nav-container {
		height: 90vh;
		max-width: none;
		margin-top: 0;
		margin-left: 0;
		display: block;
	}

	.main-nav-link {
		border-bottom: 1px solid var(--8);
		color: var(--slate-grey);
		text-transform: capitalize;
		padding: 1.3rem 1rem 1.3rem 2rem;
		line-height: 1rem;
	}

	.main-nav-link:hover {
		background-color: var(--orange);
		box-shadow: none;
		color: var(--slate-grey);
	}

	.main-nav-link.w--current {
		color: #0a706b;
		background-color: #fff;
	}

	.main-nav-link.with-arrow {
		background-image: url('/20181016082423/assets/images/arrow-dark-grey.svg');
		background-position: 95%;
		background-repeat: no-repeat;
		background-size: auto 30px;
	}

	.mob-menu-btn {
		justify-content: flex-end;
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}

	.mob-menu-btn.w--open {
		color: rgba(0, 0, 0, 0);
		background-color: rgba(0, 0, 0, 0);
		background-image: url('/20181016082423/assets/images/exit-icon.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: auto 20px;
		border-radius: 50px;
	}

	.inside-page-header-container {
		flex: 0 auto;
	}

	.h2 {
		font-size: 30px;
	}

	.inside-page-container.white-background.left-nav {
		flex-direction: column;
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 3rem;
	}

	.h3-header-link {
		font-size: 26px;
	}

	.content-container {
		width: 100%;
		padding-left: 0;
	}

	.content-container.full {
		margin-bottom: 20px;
	}

	.rightnav-holder {
		width: auto;
		margin-left: 0;
	}

	.leftnav-holder {
		display: none;
	}

	.leftnavigation {
		width: 100%;
		flex: 0 auto;
		margin-top: 10px;
		margin-bottom: 0;
		padding-top: 0px;
	}

	.qt-accordion-item-content-wrap {
		padding-left: 0;
		padding-right: 0;
	}

	.h4 {
		font-size: 22px;
	}

	.inside-page-content {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.inside-page-content.left---right {
		padding-left: 0;
		padding-right: 0;
	}

	.h4-header-link {
		font-size: 22px;
	}

	.main-content {
		flex-direction: column;
	}

	.main-content.product-page {
		margin-bottom: 0;
	}

	.h2-header-link {
		font-size: 30px;
	}

	.h3 {
		font-size: 26px;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.qt-data-rows-wrap {
		min-height: auto;
		margin-bottom: 0.5rem;
	}

	.main-nav-link-icon {
		border-bottom: 1px solid var(--8);
		color: var(--slate-grey);
		text-transform: capitalize;
		background-image: url('/20181016082423/assets/images/user.svg');
		background-position: 32px;
		background-repeat: no-repeat;
		background-size: 27px;
		padding: 1.3rem 1rem 1.3rem 4.4rem !important;
		line-height: 1rem;
	}

	.main-nav-link-icon:hover {
		background-color: var(--orange);
		box-shadow: none;
		color: var(--slate-grey);
	}

	.main-nav-link-icon.w--current {
		color: #0a706b;
		background-color: #fff;
	}

	.main-nav-link-icon.wishlist {
		background-image: url('/20181016082423/assets/images/heart.svg');
	}

	.main-nav-link-icon.phone {
		background-image: url('/20181016082423/assets/images/call.svg');
	}

	.faq-item.first {
		margin-top: 10px;
	}

	.filter-ui {
		padding-left: 0;
		padding-right: 0;
	}

	.category-card {
		width: 80%;
		padding-left: 40px;
	}

	.lvl2-cat-img-circle {
		max-height: 70px;
		max-width: 70px;
		min-height: 70px;
		min-width: 70px;
	}

	.lvl3-cat-name-wrapper {
		padding-left: 0;
	}

	.lvl-3-cat-name-carousel-wrap {
		flex: 0 auto;
	}

	.slider-4 {
		height: auto;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 32px;
	}

	.slide-sm {
		width: 25%;
		height: auto;
		background-color: var(--4);
		border-radius: 100px;
		margin-right: 10px;
		padding: 0 10px 0 0;
	}

	.cat-3-title-img-wraper {
		height: auto;
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		flex-direction: column;
		justify-content: flex-start;
		margin-top: 0;
		padding: 2px 10px;
	}

	.cat-3-title-img-wraper.hide {
		display: none;
	}

	.lvl3-cat-img-circle {
		width: 50px;
		height: 50px;
		min-height: 50px;
		min-width: 50px;
		outline-color: var(--8);
		outline-offset: 0px;
		background-color: #fff;
		outline-width: 1px;
		outline-style: solid;
	}

	.mask {
		height: auto;
	}

	.pagination {
		width: 40px;
		height: 40px;
		box-shadow: none;
		border-width: 6px;
		border-radius: 4px;
		flex-wrap: wrap;
		justify-content: center;
		top: 1%;
	}

	.pagination:hover {
		box-shadow: none;
	}

	.lvl-1-title-img-wraper {
		padding-right: 16px;
	}

	.brand-category-list {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.product-img-grid {
		width: auto;
		height: 500px;
	}

	.product-detail-wrap {
		width: auto;
	}

	.secure-payments-wrap {
		margin-bottom: 16px;
	}

	.additional-product-content {
		width: 100%;
		margin-top: 0;
	}

	.dropdown-toggle-4 {
		margin-left: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.quick-filter {
		border: 1px none var(--30);
		background-color: #fff;
		border-radius: 100px;
		justify-content: center;
		align-self: center;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 10px;
	}

	.quick-filter.alt {
		cursor: pointer;
		border-style: none;
		justify-content: center;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
	}

	.filters-wrap {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		display: flex;
	}

	.filter-icon {
		width: 20px;
		height: 20px;
		background-image: url('/20181016082423/assets/images/filters-1c2b3c.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		margin-left: 10px;
		padding-bottom: 0;
		padding-right: 0;
	}

	.pagination-div {
		padding-top: 0.5rem;
	}

	.filter-products-link {
		margin-left: 0;
	}

	.dropdown-toggle-4-copy {
		padding-left: 10px;
	}

	.filter-ui-mobile {
		z-index: 2000;
		height: 100%;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-direction: column;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		align-items: center;
		padding: 2rem;
		display: none;
		position: absolute;
		top: 100px;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}

	.x-wrap {
		width: 100%;
		height: 25px;
		justify-content: flex-end;
		margin-bottom: 16px;
		padding-right: 16px;
		display: flex;
	}

	.heading-18 {
		text-align: left;
		line-height: 15px;
	}

	._6pcs {
		justify-content: space-between;
	}

	._4pcs {
		justify-content: space-between;
		display: flex;
	}

	.titles-headings-copy-copy {
		text-align: left;
		font-size: 30px;
		line-height: 34px;
	}

	.extrawrapperdelivery {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		align-items: center;
		display: flex;
	}
}

@media screen and (max-width: 767px) {
	.footer-bottom-list {
		text-align: center;
	}

	.brix---header-logo {
		max-width: 90%;
	}

	.brix---icon-small-hover {
		width: 24px;
	}

	.brix---top-bar-close-icon {
		align-items: center;
		padding-left: 16px;
		padding-right: 16px;
	}

	.brix---top-bar-right-col {
		margin-bottom: 12px;
	}

	.brix---top-bar-flex-h-center {
		flex-direction: column;
		justify-content: flex-end;
	}

	.brix---top-bar-wrapper {
		padding-left: 10px;
		padding-right: 10px;
	}

	.cf-visual-features-item.navbar.w--current {
		padding: 14px;
	}

	.cf-navigation-item-heading {
		font-size: 18px;
	}

	.cf-visual-features-items-container {
		width: 100%;
		grid-row-gap: 70px;
		grid-template-columns: 1fr;
	}

	.cf-visual-features-items-container.nav {
		max-width: 400px;
		grid-template-columns: 1fr;
	}

	.cf-visual-features-items-container.nav.blogs {
		grid-template-columns: 1fr;
	}

	.titles-headings.newsletter {
		text-align: center;
		font-size: 28px;
		line-height: 32px;
	}

	.property-spotlight-image-link-3.product2 {
		max-height: 160px;
	}

	.icon-5 {
		color: #8b8b8b;
		font-size: 2.05rem;
	}

	.widget-outer-warp {
		align-items: center;
		display: flex;
	}

	.prop-spotlight-details-wrapper-3 {
		padding-bottom: 10px;
	}

	.slider-content {
		width: 100%;
		padding-right: 0;
	}

	.refined-widget {
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		top: 30px;
	}

	.discount,
	.clearance {
		padding: 12px 10px;
	}

	.tab-link-all,
	.tab-link-all.w--current {
		padding-left: 10px;
		padding-right: 10px;
	}

	.tabs-menu {
		padding-left: 0%;
	}

	.uui-navbar02_blog-item {
		padding: 0;
	}

	.uui-navbar02_blog-item:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-button-row {
		align-self: stretch;
	}

	.uui-button-link {
		font-size: 1rem;
	}

	.uui-button-link:hover {
		color: #6941c6;
	}

	.main_container__social-media-container {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		justify-items: center;
		display: grid;
	}

	.feature {
		text-align: left;
	}

	.feature-container {
		width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		padding-left: 25%;
	}

	.feature-description {
		text-align: left;
	}

	.signup {
		padding-bottom: 0;
	}

	.footer_social-link:hover,
	.footer_legal-link:hover {
		color: var(--untitled-ui-gray400);
	}

	.footer_legal-list {
		margin-bottom: 1rem;
	}

	.footer-small-text {
		text-align: center;
	}

	.footer_legal-list-wrapper {
		flex-direction: column-reverse;
	}

	.footer_bottom-wrapper {
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.uui-footer05_link:hover {
		color: var(--untitled-ui-gray500);
	}

	.footer_link-list {
		align-items: center;
	}

	.uui-logo_logotype {
		margin-left: 0;
	}

	.footerl_logo-link {
		margin-bottom: 1rem;
	}

	.footer_top-wrapper {
		grid-row-gap: 2rem;
		grid-template-columns: 1fr;
		align-self: center;
		justify-items: center;
	}

	.uui-footer05_form {
		flex-direction: column;
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.uui-footer05_newsletter-wrapper {
		flex-direction: column;
		align-items: center;
		margin-bottom: 16px;
		margin-left: 16px;
		margin-right: 16px;
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.footer-padding-vertical {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.footer-padding {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.footer-grip-link.white-logo {
		width: 133px;
		align-self: center;
		margin-bottom: 1rem;
		padding-left: 0;
	}

	.grip-logo-text-box-2.webdevby-white {
		text-align: right;
	}

	.tab-pane-exteriors {
		height: 100%;
	}

	.tabs-content {
		height: 100%;
		top: 0;
	}

	.primary-btn {
		display: block;
	}

	.secondary-btn.hide {
		display: none;
	}

	.category-link {
		width: 100%;
		flex-wrap: wrap;
		align-content: center;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.dropdown-toggle {
		display: none;
	}

	.language-dropdown-list.w--open {
		right: auto;
	}

	.titles-headings-copy {
		margin-bottom: 0;
		font-size: 28px;
	}

	.left-arrow-2 {
		right: 14vw;
	}

	.orange-price {
		padding-bottom: 0;
	}

	.uui-navbar02_dropdown-link-list {
		max-width: none;
		grid-row-gap: 0.5rem;
	}

	.tab-change_style-tab {
		margin-right: 0;
	}

	.tab-change_tab-link {
		white-space: nowrap;
		padding-left: 12px;
		padding-right: 12px;
		overflow: visible;
	}

	.uui-navbar02_dropdown-content-left-3 {
		grid-row-gap: 1.5rem;
		flex-direction: column;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.cf-dropdown-list--nav-3.w--open {
		width: 90%;
	}

	.last-item-on-carousel {
		width: 100%;
		padding-right: 0;
	}

	.icon-5-copy {
		font-size: 2.05rem;
	}

	.hero-section {
		height: auto;
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr;
		padding-top: 0;
		padding-bottom: 0;
	}

	.categories-section {
		justify-content: center;
	}

	.category-img {
		max-height: 300px;
	}

	.section-wrapper {
		padding: 0 0% 40px;
	}

	.section-wrapper.vertical {
		padding-left: 0;
		padding-right: 0%;
	}

	.section-wrapper.vertical.grey-bg {
		padding-top: 60px;
	}

	.section-wrapper.vertical.grey-bg.padding {
		margin-top: 0;
		padding-bottom: 60px;
	}

	.section-wrapper.vertical.grey-bg {
		padding-bottom: 0;
		padding-left: 16px;
		padding-right: 16px;
	}

	.section-wrapper.grey-bg {
		padding: 16px;
	}

	.section-wrapper.spavce {
		padding-top: 60px;
		padding-bottom: 20px;
	}

	.section-wrapper.space-up {
		padding-top: 20px;
		padding-bottom: 10px;
	}

	.secondary-title {
		text-align: left;
		font-size: 28px;
	}

	.promo-paragraph {
		text-align: left;
	}

	.uui-blogsection04_category-link {
		padding-top: 0.125rem;
		padding-bottom: 0.125rem;
		padding-right: 0.5rem;
		font-size: 0.75rem;
	}

	.product-slider {
		height: 50%;
		flex-direction: column;
		display: flex;
	}

	.product-slider.tablet {
		height: auto;
	}

	.partners-module {
		margin-bottom: 0px;
	}

	.product-slider.tablet.blog {
		margin-bottom: 40px;
		padding-top: 0;
		padding-bottom: 0;
		top: 0;
		overflow: visible;
		margin-top: 10px;
	}

	.product-slider.brands {
		height: auto;
		margin-top: 0;
	}

	.blog-title {
		font-size: 1.25rem;
	}

	.uui-blogsection04_meta-wrapper {
		font-size: 0.75rem;
	}

	.uui-blogsection04_image-link {
		margin-bottom: 1.5rem;
	}

	.uui-blogsection04_image-link:hover {
		box-shadow: none;
	}

	.product-slider-arrow {
		box-shadow: none;
		border-width: 5px;
		border-radius: 4px;
		flex-direction: column;
		margin-top: 0.5rem;
		margin-left: auto;
		margin-right: auto;
		padding-top: 0;
		top: -50px;
		bottom: 0%;
		left: auto;
		right: 15%;
	}

	.product-slider-arrow.property-arrow-right {
		box-shadow: none;
		flex: 0 auto;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		top: -50px;
		bottom: 0%;
		left: auto;
		right: 3%;
	}

	.product-slider-arrow.property-arrow-right.product {
		margin-left: auto;
		margin-right: auto;
		top: -182px;
		bottom: auto;
		right: 0;
	}

	.product-slider-arrow.property-arrow-right.itm {
		width: 40px;
		height: 40px;
		background-color: var(--4);
		top: -35px;
	}

	.product-slider-arrow.property-arrow-right.itm:hover {
		background-color: var(--slate-grey);
	}

	.product-slider-arrow.property-arrow-right.blog {
		margin-left: auto;
		margin-right: auto;
		top: -14%;
		left: auto;
		right: 3%;
	}

	.product-slider-arrow.property-arrow-right.lvl2slider {
		display: none;
	}

	.product-slider-arrow.product {
		width: 40px;
		height: 40px;
		margin-bottom: 0;
		margin-right: 0;
		top: -182px;
		left: auto;
		right: 52px;
	}

	.product-slider-arrow.itm {
		background-color: var(--4);
		margin-top: -15px;
		top: -35px;
	}

	.product-slider-arrow.itm:hover {
		background-color: var(--slate-grey);
		color: #fff;
	}

	.product-slider-arrow.blog {
		z-index: 5;
		width: 40px;
		height: 40px;
		margin-left: auto;
		margin-right: auto;
		top: -14%;
		bottom: auto;
		left: auto;
		right: 15%;
		overflow: visible;
	}

	.product-slider-arrow.blog.lvl2slider {
		height: 45px;
	}

	.product-slider-arrow.itm-copy {
		width: 40px;
		height: 40px;
		background-color: var(--4);
		margin-top: 0;
		margin-bottom: 0;
		margin-right: auto;
		top: -19%;
		right: 15%;
	}

	.product-slider-arrow.category {
		background-color: var(--4);
		margin-top: -15px;
		top: -35px;
	}

	.product-slider-arrow.category:hover {
		background-color: var(--slate-grey);
		color: #fff;
	}

	.product-spotlight-slide {
		width: 50%;
		margin-top: 10px;
		margin-left: 0%;
		margin-right: 0%;
	}

	.product-spotlight-slide.brand {
		width: 50%;
		margin-top: 10px;
		margin-left: 0;
	}

	.product-spotlight-slide.blog {
		width: 100%;
		height: 100%;
		margin-top: 0;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.icon {
		border-radius: 4px;
		flex: 1;
		padding-top: 0;
		padding-right: 0;
		font-size: 1.2rem;
		position: fixed;
		top: 0;
		right: 1rem;
	}

	.product-spotlight-mask {
		width: 100%;
		margin-top: 0;
		padding-top: 0;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.product-spotlight-mask.blog {
		margin-top: 0;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.phone-div {
		display: none;
	}

	.slider-3 {
		height: 300px;
	}

	.slide-nav-3 {
		display: none;
	}

	.copy-content-wrapper-2 {
		align-items: center;
		margin-top: 5%;
		margin-left: auto;
		padding-right: 0;
	}

	.hero-heading {
		font-size: 40px;
	}

	.hero-paragraph {
		width: 100%;
		text-align: center;
	}

	.div-block {
		width: 60%;
		margin-bottom: 0;
		margin-left: 16px;
	}

	.icon-wrapper {
		margin-right: 20px;
	}

	.labels.space {
		display: none;
	}

	.labels.space.mobile {
		flex-direction: row;
		display: flex;
	}

	.search-bar-2 {
		min-width: auto;
		margin-right: auto;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		left: 0;
		right: 0;
	}

	.uui-navbar02_dropdown-content-left-5 {
		grid-row-gap: 1.5rem;
		flex-direction: column;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.login {
		display: none;
	}

	.search-icon {
		background-size: 17px;
		margin-right: 5px;
	}

	.header-content-wrapper-2 {
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		grid-template-columns: 0.25fr 1fr 0.5fr;
		justify-items: start;
	}

	.div-header-right-2 {
		align-items: flex-end;
	}

	.drop-down-list-2.w--open {
		width: 90%;
	}

	.header-logo-link {
		justify-content: center;
		display: flex;
	}

	.language-div-2 {
		display: none;
	}

	.uui-navbar02_dropdown-content-right-4 {
		padding: 2rem 1rem;
	}

	.header-wrapper {
		padding-left: 16px;
		padding-right: 16px;
	}

	.link-2 {
		align-items: flex-end;
		margin-top: 5px;
	}

	.link-2.mob {
		display: none;
	}

	.container-6 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.gdrp-section-icon.just-icon {
		width: 16%;
		min-height: 75px;
	}

	.open-gdrp-icon-2 {
		margin-right: 1rem;
	}

	.gdrp-section-2 {
		display: none;
	}

	.cookie-div {
		width: 85%;
		justify-content: flex-start;
		padding-left: 10px;
		padding-right: 10px;
	}

	.exit-icon-div {
		width: 20px;
		height: 20px;
	}

	.cookie-text-para-2 {
		width: 85%;
	}

	.button-underline {
		margin-top: 3px;
		margin-left: -27px;
	}

	.button-underline.gdpr {
		margin-left: 40px;
	}

	.socials {
		flex-direction: row;
	}

	.product-slide-content-wrapper {
		padding-right: 10px;
	}

	.product-slide-content-wrapper:hover {
		box-shadow: 0 0 7px rgba(0, 0, 0, 0.23);
	}

	.product-slide-content-wrapper.max {
		display: none;
	}

	.product-slide-content-wrapper.max._5 {
		padding-right: 0;
		display: flex;
	}

	.product-slide-content-wrapper.hide {
		display: none;
	}

	.product-slide-content-wrapper.hide.single {
		display: flex;
	}

	.product-slide-content-wrapper.single {
		width: 75%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.product-slide-content-wrapper._3 {
		display: none;
	}

	.product-slide-content-wrapper._4 {
		padding-right: 0;
	}

	.slide-product {
		width: 50%;
	}

	.slidermask {
		height: auto;
		max-height: 550px;
		min-height: 520px;
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.slide-promo-item {
		width: 50%;
		padding-left: 25px;
		padding-right: 25px;
		position: static;
	}

	.slidermask-promo-items {
		position: static;
		overflow: hidden;
	}

	.categories-slider-widget {
		display: flex;
	}

	.categories-slider-widget.mob {
		height: 100%;
		display: block;
	}

	.title-padding {
		align-self: flex-start;
		margin-left: 20px;
	}

	.icon-7,
	.icon-8 {
		font-size: 30px;
	}

	.brand {
		margin-left: 0.65rem;
	}

	.menu-button-text {
		display: none;
	}

	.mobile-menu-icon {
		align-self: center;
	}

	.navbar {
		padding-left: 0.35rem;
		padding-right: 1rem;
	}

	.container {
		max-width: 767px;
	}

	.mob-menu-btn {
		order: -1;
		align-items: center;
		padding-left: 0;
	}

	.heading-5-copy {
		font-size: 30px;
	}

	.inside-page-title-banner {
		padding-left: 32px;
		padding-right: 32px;
	}

	.block-quote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.inside-page-container.white-background {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.content-container {
		grid-template-columns: 1fr;
	}

	.content-container.full {
		text-align: left;
	}

	.intro-paragraph {
		text-align: left;
		font-size: 1.125rem;
	}

	.breadcrumbs-list-2 {
		line-height: 1.3rem;
	}

	.paragraph {
		text-align: left;
	}

	.rightnav-holder {
		width: 100%;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.qt-accordion-item-content-wrap {
		padding-left: 0;
		padding-right: 0;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.inside-page-content {
		padding-left: 0;
		padding-right: 0;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.main-content {
		text-align: center;
		align-self: center;
		align-items: center;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.qt-data-rows-wrap {
		min-height: auto;
		font-size: 0.875rem;
	}

	.filter-ui {
		padding-left: 0;
		padding-right: 0;
	}

	.fs-style_dropdown-icon {
		margin-right: 1rem;
	}

	.price {
		flex-direction: column;
	}

	.category-card {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.cat-2-title-img-wraper {
		margin-bottom: 10px;
	}

	.see-more-button {
		margin-top: 16px;
	}

	.lvl3-cat-name-wrapper {
		flex-direction: column;
	}

	.slider-4 {
		align-items: flex-start;
	}

	.slide-sm {
		width: 38%;
	}

	.pagination {
		box-shadow: none;
		border-width: 5px;
		border-radius: 4px;
		flex-direction: column;
		margin-top: 0.5rem;
		margin-left: auto;
		margin-right: auto;
		padding-top: 0;
		top: -70px;
		bottom: 0%;
		left: auto;
		right: 15%;
	}

	.bottom-paragraph {
		text-align: left;
		margin-top: 0;
	}

	.brand-category-list {
		grid-template-columns: 1fr;
	}

	.product-img-grid {
		width: 100%;
		display: none;
	}

	.additional-product-content {
		width: auto;
	}

	.mobile-img-product-slider {
		width: 100%;
		height: 400px;
		display: block;
	}

	.dummy-img-slide {
		background-image: url('/20181016082423/assets/images/DUMMY-IMG.jpg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.pagination-div {
		justify-content: space-between;
	}

	.filter-products-link {
		margin-left: 0;
	}

	.product-img {
		width: auto;
		max-height: 200px;
		margin-left: auto;
		margin-right: auto;
	}

	.titles-headings-copy-copy {
		margin-bottom: 0;
		font-size: 28px;
	}
}

@media screen and (max-width: 479px) {
	.strong {
		align-items: center;
		margin-right: 0;
	}

	._60-off-car-batteries-use-code-batt60 {
		text-align: left;
		font-size: 12px;
	}

	.div-header-store-notice-2 {
		justify-content: flex-start;
		align-items: flex-end;
	}

	.text-9 {
		text-align: left;
		justify-content: flex-start;
		align-items: center;
		margin-right: 10px;
		font-size: 14px;
		display: flex;
	}

	.brix---header-logo {
		max-width: 84%;
	}

	.brix---icon-small-hover {
		width: 20px;
	}

	.brix---top-bar-close-icon {
		padding-left: 12px;
		padding-right: 8px;
	}

	.brix---top-bar-right-col {
		margin-bottom: 0;
	}

	.brix---top-bar-flex-h-center {
		justify-content: center;
		margin-left: 0;
	}

	.brix---top-bar-wrapper {
		padding-left: 12px;
		padding-right: 12px;
		font-size: 17px;
		line-height: 26px;
	}

	.cf-visual-features-item.navbar {
		padding: 10px;
		font-size: 14px;
	}

	.cf-visual-features-items-container.nav {
		max-width: none;
		max-width: 230px;
	}

	.cf-navigation-drop-container-2.features {
		padding: 0;
	}

	.cf-growth-wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}

	._3-categories {
		margin-top: 0;
		margin-bottom: 0;
	}

	.property-spotlight-image-link-3 {
		border-bottom-style: none;
	}

	.property-spotlight-image-link-3.product2 {
		max-height: 150px;
		background-position: 50%;
	}

	.listing-details {
		font-size: 0.725rem;
	}

	.listing-details.large-was-price.no-strike {
		text-decoration: none;
	}

	.icon-5 {
		font-size: 1.5rem;
		font-weight: 700;
	}

	.icon-5:hover {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 50px;
	}

	.widget-outer-warp {
		align-items: flex-start;
	}

	.prop-spotlight-details-wrapper-3 {
		padding-bottom: 10px;
	}

	.slider-content {
		padding-right: 0;
	}

	.refined-widget {
		width: 100%;
		height: auto;
		align-items: flex-end;
		margin-top: 0;
		margin-bottom: 30px;
	}

	.refined-widget.mob {
		margin-left: 0;
		margin-right: 0;
		padding-left: 10px;
	}

	.refined-widget.mob.categories {
		margin-top: 0;
	}

	.discount {
		font-size: 14px;
		line-height: 16px;
	}

	.discount.small {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;
		line-height: 16px;
	}

	.clearance {
		font-size: 14px;
		line-height: 16px;
	}

	.clearance.small {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;
		line-height: 16px;
	}

	.title-container {
		margin-bottom: 20px;
		margin-left: 0%;
	}

	.tab-link-all {
		width: 100%;
		background-color: #fff;
		border-radius: 5px;
		margin-left: 0;
		margin-right: 0;
		padding-left: 5px;
		padding-right: 5px;
	}

	.tab-link-all.w--current {
		margin-left: 0;
		margin-right: 0;
		padding-left: 10px;
		padding-right: 10px;
	}

	.tab-link {
		max-width: none;
		text-align: center;
	}

	.tabs-menu {
		width: 96%;
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		align-content: center;
		justify-content: space-between;
		align-self: flex-start;
		align-items: center;
		justify-items: start;
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;
		padding-left: 6px;
		padding-right: 6px;
		display: grid;
	}

	.tabs {
		flex-direction: column;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.uui-navbar02_blog-item {
		flex-direction: column;
		display: flex;
	}

	.uui-navbar02_dropdown-blog-item-wrapper {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
	}

	.uui-navbar02_blog-content {
		margin-top: 0.5rem;
	}

	.uui-navbar02_dropdown-content-wrapper {
		width: 100%;
	}

	.main_container__social-media-container {
		grid-template-columns: 1fr;
		justify-items: start;
		margin-top: 0;
	}

	.social-media-link__social-media-image {
		width: 4.5em;
		height: 4.4em;
	}

	.social-media-link__social-media-image.small {
		width: 4.5em;
		height: 4.5em;
	}

	.feature-container {
		padding-left: 0%;
	}

	.signup {
		padding-left: 4%;
		padding-right: 4%;
	}

	.footer_bottom-wrapper {
		margin-top: 1rem;
		padding-top: 1rem;
	}

	.uui-footer05_link {
		padding-top: 0.125rem;
		padding-bottom: 0;
	}

	.uui-footer05_link-list-heading {
		margin-bottom: 0.5rem;
	}

	.footer_top-wrapper {
		grid-column-gap: 0.5rem;
		grid-row-gap: 1rem;
		grid-template-columns: 1fr;
	}

	.uui-footer05_form {
		grid-row-gap: 12px;
		flex-direction: column;
		grid-template-columns: 1fr;
	}

	.uui-footer05_form-block {
		width: 80%;
	}

	.uui-footer05_newsletter-wrapper {
		margin-bottom: 20px;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	.footer-padding-vertical {
		padding-top: 1.7rem;
		padding-bottom: 1.7rem;
	}

	.footer-grip-link.white-logo {
		text-align: center;
		margin-top: 0.25rem;
	}

	.tabs-content {
		width: 100%;
	}

	.stilhi.square {
		background-attachment: scroll, scroll, scroll;
	}

	.dewalt.square,
	.claber.square {
		background-size: contain, auto, cover;
	}

	.category-link {
		width: 100%;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-bottom: 0.5rem;
	}

	.dropdown-toggle {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.language-dropdown-list.w--open {
		right: 0;
	}

	.titles-headings-copy {
		margin-bottom: 10px;
		font-size: 25px;
		line-height: 32px;
	}

	.right-arrow-2 {
		bottom: 1rem;
		right: 3vw;
	}

	.left-arrow-2 {
		top: auto;
		bottom: 1rem;
		left: auto;
		right: 25vw;
	}

	.was-wording,
	.is-now,
	.listing-details-copy,
	.listing-details-copy {
		font-size: 0.725rem;
	}

	.orange-price {
		padding-bottom: 0;
	}

	.uui-navbar02_dropdown-link-2 {
		padding-left: 0;
	}

	.cf-dropdown-toggle---nav-3 {
		font-size: 16px;
	}

	.uui-navbar02_dropdown-content-left-3 {
		grid-template-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.tab-change_tab-menu-2 {
		overflow: auto;
	}

	.last-item-on-carousel {
		height: 382px;
		padding-right: 0;
	}

	.section-wrapper {
		padding-bottom: 0;
		padding-left: 16px;
		padding-right: 16px;
	}

	.section-wrapper.vertical {
		padding: 40px 10px 0;
	}

	.section-wrapper.vertical.grey-bg.padding {
		padding: 40px 16px 40px 20px;
	}

	.section-wrapper.vertical.grey-bg {
		margin-top: 0;
		margin-left: 0;
		padding: 30px 0 0;
	}

	.section-wrapper.vertical.less-top-padding {
		padding-bottom: 0;
	}

	.section-wrapper.grey-bg {
		padding-top: 20px;
		padding-bottom: 25px;
	}

	.section-wrapper.spavce {
		margin-bottom: 0;
		padding: 30px 8px 20px;
	}

	.section-wrapper.space-up {
		padding-top: 30px;
		padding-left: 8px;
		padding-right: 8px;
	}

	.tab-pane-all {
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}

	.generic-img {
		min-width: 125px;
	}

	.secondary-title-wrap {
		width: 75%;
		padding-left: 20px;
		padding-right: 16px;
	}

	.secondary-title {
		margin-top: 0;
		margin-bottom: 20px;
		font-size: 25px;
		line-height: 32px;
	}

	.promo-paragraph {
		text-align: left;
	}

	.text-block-35 {
		margin-left: 0;
	}

	.product-slider {
		width: 100%;
	}

	.product-slider.tablet {
		padding-top: 0;
	}

	.product-slider.tablet.blog {
		padding-top: 20px;
		top: 0;
	}

	.product-slider.brands {
		margin-top: 0;
	}

	.uui-text-size-medium-7 {
		margin-left: 0;
	}

	.product-slider-arrow {
		width: 40px;
		height: 40px;
		background-color: var(--4);
		box-shadow: none;
		border-width: 4px;
		border-radius: 4px;
		margin-top: 0;
		margin-left: auto;
		margin-right: 10px;
		padding-right: 0;
		font-size: 1.25rem;
		transition: all 0.3s;
		top: -43px;
		bottom: 0%;
		left: auto;
		right: 50px;
	}

	.product-slider-arrow.property-arrow-right {
		width: 40px;
		height: 40px;
		box-shadow: none;
		border-radius: 4px;
		flex: 0 auto;
		justify-content: center;
		align-items: center;
		display: flex;
		top: -43px;
		bottom: 0%;
		left: auto;
		right: 8px;
	}

	.product-slider-arrow.property-arrow-right.product {
		width: 40px;
		height: 40px;
		margin-left: auto;
		margin-right: 16px;
		right: 0;
	}

	.product-slider-arrow.property-arrow-right.itm {
		margin-right: 8px;
		bottom: 0%;
	}

	.product-slider-arrow.property-arrow-right.blog {
		align-self: center;
		margin-left: auto;
		margin-right: 0;
		top: -20%;
		right: 0%;
	}

	.product-slider-arrow.property-arrow-right.itm-copy {
		align-content: flex-start;
		top: -40%;
	}

	.product-slider-arrow.property-arrow-right.lvl2slider {
		display: none;
	}

	.product-slider-arrow.property-arrow-right.category {
		top: -50px;
		right: 8px;
	}

	.product-slider-arrow.product {
		margin-left: auto;
		margin-right: 69px;
		left: auto;
		right: 0;
	}

	.product-slider-arrow.itm {
		margin-top: -58px;
		margin-right: 60px;
		bottom: 0%;
		right: 0;
	}

	.product-slider-arrow.blog {
		margin-right: 52px;
		top: -20%;
		right: 0%;
	}

	.product-slider-arrow.blog.lvl2slider {
		margin-left: 0;
		margin-right: 0;
	}

	.product-slider-arrow.itm-copy {
		margin-left: auto;
		top: -31%;
		bottom: 0%;
	}

	.product-slider-arrow.itm-copy.left {
		margin-right: 10px;
		top: -40%;
	}

	.product-slider-arrow.category {
		margin-right: 0;
		bottom: 0%;
		right: 61px;
		top: -50px;
	}

	.product-spotlight-slide {
		width: 100%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.product-spotlight-slide.brand {
		width: 50%;
		min-width: 100px;
		padding-left: 8px;
		padding-right: 8px;
		transition-property: none;
	}

	.product-spotlight-slide.blog {
		padding-left: 1px;
		padding-right: 0;
	}

	.icon {
		border-radius: 4px;
		flex-flow: wrap;
		align-content: center;
		justify-content: center;
		margin-left: 0;
		margin-right: 0;
		font-size: 1.2rem;
		transition: all 0.2s;
		display: flex;
	}

	.uui-blogsection04_title-link {
		margin-left: 0;
	}

	.product-spotlight-mask {
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.product-spotlight-mask.blog {
		margin-top: 0;
		margin-bottom: 20px;
		padding-top: 0;
		padding-bottom: 40px;
	}

	.div-block {
		width: 66%;
		margin-top: 20px;
		margin-bottom: 0;
		margin-left: 0;
		padding-right: 16px;
	}

	.search-bar-2 {
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.search-bar-wrap-2 {
		width: 100%;
		align-items: center;
	}

	.x-close {
		margin-bottom: 10px;
		position: static;
	}

	.uui-navbar02_dropdown-content-left-5 {
		grid-template-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.search {
		justify-content: flex-end;
		margin-right: 0;
	}

	.login {
		width: 18px;
	}

	.toggle-2 {
		font-size: 16px;
	}

	.search-icon {
		margin-right: 0;
	}

	.header-content-wrapper-2 {
		grid-template-rows: auto;
		grid-template-columns: 0.25fr 1fr 0.5fr;
		align-content: stretch;
		align-self: center;
		align-items: center;
		padding-top: 10px;
	}

	.div-header-right-2 {
		grid-column-gap: 10px;
		margin-right: 0;
		padding-left: 0;
	}

	.header-logo-link {
		justify-content: center;
		align-self: flex-start;
		display: flex;
	}

	.header-logo-link.w--current {
		justify-content: center;
	}

	.dropdown-3 {
		margin-right: -15px;
	}

	.uui-navbar02_dropdown-content-right-4 {
		max-width: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.header-wrapper {
		height: auto;
		padding: 10px 16px;
	}

	.ecommerce-icon-div-2 {
		grid-column-gap: 11px;
		justify-content: flex-end;
		margin-right: 10px;
	}

	.container-6 {
		padding-left: 0;
		padding-right: 0;
	}

	.container-6.footer {
		flex-direction: column;
		align-items: center;
	}

	.container-6.footer.gdrp {
		flex: 0 auto;
		align-items: center;
		padding-top: 3rem;
		padding-left: 0;
		padding-right: 0;
	}

	.home-content-footer-2 {
		background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('/20181016082423/assets/images/background-pattern.jpg');
		background-position: 0 0, 50% 0;
		background-repeat: repeat, repeat-y;
		background-size: auto, 2000px;
		background-attachment: scroll, scroll;
	}

	.gdrp-section-icon.just-icon {
		width: 30%;
		min-height: 75px;
	}

	.open-gdrp-icon-2 {
		margin-right: 0;
	}

	.gdrp-link-copy-2 {
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
		font-size: 12px;
	}

	.gdrp-link-2 {
		margin-right: 0;
		font-size: 12px;
	}

	.gdrp-section-2 {
		display: none;
	}

	.cookie-div {
		width: 85%;
		min-width: auto;
		grid-column-gap: 16px;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
	}

	.exit-icon-div {
		width: 20px;
		height: 20px;
	}

	.cookie-text-para-2 {
		margin-bottom: 0.5rem;
		padding-left: 0;
		padding-right: 0;
		font-size: 0.612rem;
		line-height: 1.075rem;
	}

	.button-underline {
		align-items: flex-start;
		margin-left: 0;
	}

	.button-underline.gdpr {
		margin-top: 0;
	}

	.product-slide-content-wrapper {
		flex-wrap: nowrap;
	}

	.product-slide-content-wrapper.last {
		width: auto;
		display: flex;
	}

	.product-slide-content-wrapper.single {
		width: 90%;
	}

	.slide-product {
		width: 100%;
		padding-bottom: 30px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.blog-link {
		margin-left: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.text-block-47 {
		text-align: center;
	}

	.slide-promo-item {
		width: 50%;
		padding-left: 8px;
		padding-right: 8px;
	}

	.slidermask-promo-items {
		margin-bottom: 20px;
	}

	.categories-slider-widget {
		width: 100%;
		height: 100%;
		align-items: flex-end;
		margin-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.categories-slider-widget.mob {
		margin-bottom: 0;
	}

	.title-padding {
		align-self: flex-start;
		margin-left: 10px;
	}

	.icon-7 {
		margin-left: 10px;
	}

	.icon-8 {
		margin-right: 10px;
		font-size: 30px;
	}

	.brand {
		width: 200px;
		height: 65px;
		flex-shrink: 0;
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
		margin-right: 0.5rem;
	}

	.brand.w--current {
		float: none;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.menu-button-text {
		font-size: 0.7rem;
		display: none;
	}

	.mob-menu {
		margin-top: 85px;
	}

	.navbar {
		align-items: center;
		padding-right: 0.5rem;
		display: flex;
	}

	.container,
	.container.nav-container {
		max-width: none;
	}

	.mob-menu-btn {
		padding: 0;
	}

	.heading-5-copy {
		font-size: 25px;
		line-height: 27px;
	}

	.inside-page-container.white-background {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.inside-page-container.white-background.left-nav {
		padding-left: 16px;
		padding-right: 16px;
	}

	.content-container.full {
		grid-column-gap: 10px;
		grid-row-gap: 10px;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	.breadcrumbs-list-2 {
		line-height: 1.3rem;
	}

	.leftnavigation {
		margin-top: -100px;
		margin-bottom: 0;
		padding-top: 120px;
		padding-bottom: 0;
	}

	.div-block-120 {
		display: none;
	}

	.faq-answer {
		object-fit: fill;
	}

	.dropdown-4 {
		justify-content: center;
	}

	.product-row.hide {
		grid-column-gap: 10px;
		grid-row-gap: 10px;
	}

	.category-card {
		width: 100%;
		padding-left: 32px;
		padding-right: 32px;
	}

	.lvl2-cat-img-circle {
		width: 50px;
		height: 50px;
	}

	.slider-4 {
		align-items: flex-start;
	}

	.slide-sm {
		width: 42%;
	}

	.lvl3-cat-img-circle {
		max-height: 50px;
		max-width: 50px;
		min-width: 50px;
	}

	.pagination {
		width: 40px;
		height: 40px;
		background-color: var(--4);
		box-shadow: none;
		border-width: 4px;
		border-radius: 4px;
		margin-top: 0;
		margin-left: auto;
		margin-right: 10px;
		padding-right: 0;
		font-size: 1.25rem;
		transition: all 0.3s;
		top: -65px;
		bottom: 0%;
		left: auto;
		right: 15%;
	}

	.product-detail-wrap {
		width: 100%;
	}

	.div-block-125 {
		padding-right: 0;
	}

	.dropdown-toggle-4 {
		margin-right: 0;
	}

	.quick-filter {
		width: 50%;
		margin-bottom: 0;
		padding-left: 0;
	}

	.quick-filter.alt {
		width: 50%;
	}

	.filters-wrap {
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
	}

	.pagination-div {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		justify-content: center;
	}

	.pagination-block {
		float: left;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.filter-products-link {
		margin-left: 0;
		line-height: 1.3rem;
	}

	.pagination-form-wrapper {
		clear: both;
	}

	.filter-ui-mobile {
		display: none;
	}

	.heading-18 {
		font-size: 16px;
	}

	.static-slider {
		width: 100%;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-wrap: nowrap;
		justify-content: center;
		margin-left: 16px;
		margin-right: 16px;
	}

	.heading-19 {
		margin-left: 16px;
	}

	.titles-headings-copy-copy {
		margin-bottom: 10px;
		margin-left: 8px;
		font-size: 25px;
		line-height: 32px;
	}
}

#w-node-_11473caa-3c4b-ca61-53ef-6d07146f1ff5-0284cfcc {
	grid-area: span 2 / span 2 / span 2 / span 2;
}

#w-node-_4b0d5d0e-e6f3-bfec-2fb2-3757203e6442-0284cfcc,
#w-node-ea2602c0-f680-ff43-084c-a129668d4efc-0284cfcc,
#w-node-_95db2208-4e5c-42c1-09a8-8e02dacbebef-0284cfcc {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e2a6c618-7b81-8e9d-52d1-364629dd7527-0284cfcc,
#w-node-ed1fa680-376f-44e4-0973-ad9bfdea045b-0284cfcc,
#w-node-_0674171c-a516-68a0-9762-49eb6f1e99f1-0284cfcc {
	justify-self: auto;
}

#w-node-_94bd2e5e-8530-0dda-01a4-7a8f15dfffc0-0284cfcc {
	align-self: center;
}

#w-node-_5f1a6abc-fcf3-f563-cbed-b879b5227edb-b5227ea0 {
	align-self: center;
	justify-self: center;
}

#w-node-c2008bef-dab5-5385-4725-a9688cba3eb4-8cba3eb2 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c2008bef-dab5-5385-4725-a9688cba3eb6-8cba3eb2 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: center;
}

#w-node-c2008bef-dab5-5385-4725-a9688cba3eea-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3f01-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3f19-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3f30-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3f4a-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3f61-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3f79-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3f90-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3faa-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3fc1-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3fd9-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba3ff0-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba400a-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4021-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4039-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4050-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba406a-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4081-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4099-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba40b0-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba40ca-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba40e1-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba40f9-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4110-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba412a-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4141-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4159-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4170-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba418a-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba41a1-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba41b9-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba41d0-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba41ea-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4201-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4219-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4230-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba424a-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4261-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4279-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba4290-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba42aa-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba42c1-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba42d9-8cba3eb2,
#w-node-c2008bef-dab5-5385-4725-a9688cba42f0-8cba3eb2 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c2008bef-dab5-5385-4725-a9688cba436b-8cba3eb2 {
	order: 9999;
	grid-area: span 1 / span 1 / span 1 / span 1;
	align-self: center;
	justify-self: end;
}

#w-node-c2008bef-dab5-5385-4725-a9688cba437b-8cba3eb2 {
	grid-area: 1 / 3 / 2 / 4;
	justify-self: end;
}

#w-node-_47371da5-0249-cff6-595d-35c1b44ee620-0284cfd5,
#w-node-_497b0efa-af9f-7884-b57b-4fb74864667a-0284cfd5,
#w-node-_9b138427-b44f-0116-e72a-a846c92fb8e8-0284cfd5,
#w-node-_58b48448-0be4-279e-aeac-17d5908cd60a-0284cfd5,
#w-node-_47371da5-0249-cff6-595d-35c1b44ee620-0284cfd6,
#w-node-_497b0efa-af9f-7884-b57b-4fb74864667a-0284cfd6,
#w-node-_2dfcac6b-6085-94be-1369-909e1b8ceed3-0284cfd6,
#w-node-_2dfcac6b-6085-94be-1369-909e1b8cef27-0284cfd6 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_39302e4c-94f0-3ec9-4954-df4081357c45-0284cfd7 {
	grid-area: span 3 / span 4 / span 3 / span 4;
}

#w-node-_29194d41-6948-728a-45c5-b9e985f48671-0284cfd7,
#w-node-_4216e68c-5cb5-50d9-b314-b227a6752cdc-0284cfd7,
#w-node-a3604549-7761-b309-928e-4ab86c7bed0f-0284cfd7,
#w-node-ebc32fca-f119-f450-428d-90dde89bd501-0284cfd7 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
	#w-node-_11473caa-3c4b-ca61-53ef-6d07146f1ff5-0284cfcc {
		grid-area: span 1 / span 2 / span 1 / span 2;
	}

	#w-node-_5f1a6abc-fcf3-f563-cbed-b879b5227ea5-b5227ea0 {
		grid-area: span 1 / span 4 / span 1 / span 4;
		justify-self: start;
	}

	#w-node-c2008bef-dab5-5385-4725-a9688cba3eb4-8cba3eb2 {
		grid-area: span 1 / span 1 / span 1 / span 1;
		justify-self: start;
	}

	#w-node-c2008bef-dab5-5385-4725-a9688cba3eb6-8cba3eb2 {
		order: 9999;
		grid-area: span 1 / span 1 / span 1 / span 1;
		align-self: center;
		justify-self: center;
	}

	#w-node-ab356b37-25a5-dae8-4b4e-8c729dde0005-8cba3eb2 {
		order: -9999;
		justify-self: start;
	}

	#w-node-c2008bef-dab5-5385-4725-a9688cba436b-8cba3eb2 {
		order: 9999;
		grid-area: span 1 / span 1 / span 1 / span 1;
		align-self: center;
		justify-self: end;
	}

	#w-node-c2008bef-dab5-5385-4725-a9688cba436e-8cba3eb2 {
		justify-self: end;
	}
}

@media screen and (max-width: 767px) {
	#w-node-_11473caa-3c4b-ca61-53ef-6d07146f1ff5-0284cfcc {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}

	#w-node-_5f1a6abc-fcf3-f563-cbed-b879b5227ea5-b5227ea0 {
		grid-area: span 1 / span 1 / span 1 / span 1;
		justify-self: center;
	}

	#w-node-_5f1a6abc-fcf3-f563-cbed-b879b5227ecc-b5227ea0 {
		justify-self: center;
	}

	#w-node-c2008bef-dab5-5385-4725-a9688cba3eb4-8cba3eb2 {
		grid-area: span 1 / span 1 / span 1 / span 1;
		justify-self: start;
	}

	#w-node-ab356b37-25a5-dae8-4b4e-8c729dde0005-8cba3eb2 {
		align-self: center;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-_457d2542-d506-35e9-cde1-6d6fd0cda728-0284cfcc {
		justify-self: end;
	}

	#w-node-_457d2542-d506-35e9-cde1-6d6fd0cda72b-0284cfcc {
		justify-self: start;
	}

	#w-node-_457d2542-d506-35e9-cde1-6d6fd0cda72e-0284cfcc {
		justify-self: end;
	}

	#w-node-_457d2542-d506-35e9-cde1-6d6fd0cda731-0284cfcc {
		justify-self: start;
	}

	#w-node-_5f1a6abc-fcf3-f563-cbed-b879b5227ea5-b5227ea0 {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}

	#w-node-c2008bef-dab5-5385-4725-a9688cba3eb4-8cba3eb2 {
		grid-area: span 1 / span 1 / span 1 / span 1;
		align-self: center;
		justify-self: start;
	}

	#w-node-c2008bef-dab5-5385-4725-a9688cba436b-8cba3eb2 {
		justify-self: end;
	}
}

/****************** Webflow ***********************/
/****************************************************/
@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="405f0f96-fb07-df1e-6ae6-b384f727735c"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="405f0f96-fb07-df1e-6ae6-b384f727736e"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="405f0f96-fb07-df1e-6ae6-b384f7277380"] {
		opacity: 0;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.announcement-alert {
	display: none;
	padding-top: 7px;
	padding-bottom: 6px;
}

.search-bar-2 {
	display: none;
}

.header-container {
	position: fixed;
	width: 100%;
	top: 0;
}

.sticky .announcement-alert {
	display: none !important;
}

.language-dropdown-list ul,
.account-dropdown-list ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.account-dropdown-list {
	padding-top: 2.7rem;
	background-color: transparent;
	margin-left: -25px;
}

.language-dropdown-list ul li,
.account-dropdown-list ul li {
	display: block;
	margin: 0px;
	padding: 0px;
}

.account-dropdown .w-dropdown-toggle {
	padding: 0px;
	min-width: 27px;
}

.account-dropdown,
.dropdown-3 {
	position: relative;
}

.account-dropdown:hover .account-dropdown-list,
.dropdown-3:hover .language-dropdown-list {
	display: block;
}

.dropdown-3:hover .language-dropdown-list {
	margin-top: -10px;
	left: -40px;
	padding-top: 30px;
	background-color: transparent;
}

.account-link {
	background-color: #fff;
	background-image: none;
	border-radius: 5px 5px 0 0;
	font-size: 14px;
	background-position: 8px;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	border: 1px solid #f1f1f1;
	padding: 0.25rem 0.8rem 0.25rem 0.8rem;
	font-weight: 500;
	transition: all 0.45s;
	min-width: 120px;
}

.brix---top-bar-wrapper p {
	color: #fff;
	text-align: center;
	letter-spacing: 0.4px;
	font-family: Roboto Condensed, sans-serif;
	font-size: 15px;
	font-weight: 700;
	line-height: 18px;
}

.brix---top-bar-wrapper p:last-child {
	margin-bottom: 0px;
}

.menu-button-text {
	text-transform: uppercase;
}

@media (max-width: 991px) {
	.account-dropdown {
		display: none;
	}
}

@media (max-width: 479px) {
	.brix---top-bar-wrapper p {
		font-size: 12px;
	}
}

/****************** Main navigation menu **************/
/*****************************************************/
.main-nav-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: center;
	display: flex;
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: center;
}

.main-nav-list .cf-dropdown-nav-5 img {
	width: 27px;
	height: 27px;
}

.main-nav-list .cf-dropdown-nav-5:last-child img {
	width: 19px;
}


.main-nav-list .cf-dropdown-nav-5 .cf-logo-image-3 {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.tab-change_tab-content {
	width: 370px;
}

.subcat-list {
	padding-left: 10px;
	display: none;
}

.uui-navbar02_dropdown-content-3 {
	height: 75vh;
}

.uui-navbar02_dropdown-content-left-3 {
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
}

.subcat-list.active {
	display: block;
}

.subcat-list ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	width: 100%;
}

.subcat-list > ul {
	display: flex;
	flex-wrap: wrap;
}

.subcat-list ul li {
	width: 45%;
	margin-bottom: 0px;
	padding: 0px;
	display: inline-block;
}

.tab-change_tab-menu-2 {
	list-style-type: none;
	margin-bottom: 0px;
	padding-left: 0px;
}

.subcat-list ul li {
	margin: 0px;
	padding: 0px;
	padding-right: 11px;
}

.heading-3 {
	font-weight: bold;
	color: #1c2b3c;
	margin-top: 20px;
	display: inline-block;
}

.subcat-list ul li .subcat-list-lvl3 li {
	width: 100%;
	display: inline-block;
	margin-top: 7px;
}

.cf-dropdown-nav-5 .uui-navbar02_blog-content img {
	width: 100%;
	height: auto;
}

.uui-navbar02_blog-content h2 {
	color: #475467;
	margin-top: 22px;
	margin-bottom: 10px;
	padding-left: 0;
	font-size: 15px;
	font-weight: 400;
	line-height: 10px;
	font-family: Roboto, sans-serif;
}

.uui-navbar02_blog-content p {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
}

.uui-navbar02_blog-content p a {
	color: #160042;
	font-weight: 600;
}

.uui-navbar02_blog-content p a:hover {
	color: #344054;
}

.tab-link-all-2.menu.active {
	color: #fff;
	background-color: #ff8000;
	border-radius: 5px;
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_offcanvas {
		display: none !important;
		position: fixed;
		right: auto;
		z-index: 999;
		transform: translate3d(-100vw, 0, 0);
		transition: transform 0.8s ease;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	.mm-wrapper_opening .mobile-navigation-menu.mm-menu_offcanvas {
		transform: translate3d(0, 0, 0);
		transition: transform 0.9s ease;
	}

	.mobile-navigation-menu.mm-menu_offcanvas.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		transform: none !important;
	}

	.mm-wrapper_opened .mm-page__blocker.mm-slideout {
		z-index: -1;
	}
}

.mobile-navigation-menu {
	background-color: #f6f7f7;
}

.mobile-navigation-menu li a.nav-a,
.mobile-navigation-menu .mm-navbar__title {
	font-size: 1rem;
	line-height: 1.2rem;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: capitalize;
	font-weight: 700;
	font-family: Roboto, sans-serif;
	color: #1c2b3c;
	padding: 1.3rem 1rem 1.3rem 2rem;
}

.mobile-navigation-menu .mm-navbar__title {
	color: #1c2b3c !important;
	padding-top: 18px;
	padding-left: 1rem;
}

.mobile-navigation-menu .mm-navbar {
	text-align: left;
}

.mm-listitem:after {
	left: 0;
}

.mm-listitem .mm-btn_next:before {
	border-left: 0px;
}

.mm-menu .mm-listview .mm-btn_next:after,
.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #1c2b3c;
}

.mobile-navigation-menu li a.nav-a.active,
.mobile-navigation-menu li a.nav-a:hover {
	border-style: none;
	border-bottom-color: transparent;
	background-color: #ff8000;
	color: #1c2b3c;
}

.mm-panel_has-navbar {
	padding-top: 57px;
}

.mm-navbar {
	height: 57px;
}

.mm-btn_prev:before {
	left: 27px;
	top: 14px;
	border-color: #1c2b3c;
}

.mm-wrapper_opened .mob-menu-btn {
	color: rgba(0, 0, 0, 0);
	background-color: rgba(0, 0, 0, 0);
	background-image: url("/20181016082423/assets/images/exit-icon.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto 20px;
	border-radius: 50px;
}

.mobile-navigation-menu .language-dropdown-link {
	background-image: none;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
}

.cblink a,
.cblink a:hover {
	border-bottom: 0px;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
}

.cbtext a {
	color: inherit;
	text-decoration: underline;
}

._6pcs {
	flex-wrap: wrap;
}

.category-link {
	width: calc(16.6% - 20px);
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
	transform-style: preserve-3d;
	transition: transform 400ms ease;
}

.category-link:hover {
	transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1.1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
}

.section-wrapper.blog {
	display: block;
}

.section-wrapper.blog .blog-container {
	max-width: 100%;
}

.partner-carousel-link {
	display: inline-block;
	width: 100%;
	height: 100%;
}

.partners-module {
	max-width: 100%;
}

#news_module.homepage_contentbox {
	display: inline-block;
	width: 100%;
}

.feature-container._2 .feature {
	min-width: 150px;
}

.section-wrapper.vertical.grey-bg.cb-product-ribbon {
	display: block;
	text-align: center;
}

.section-wrapper.vertical.grey-bg.cb-product-ribbon .titles-headings-copy {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.icon-wrapper {
		padding: 4px 4px 10px;
		margin: 0 auto;
		text-align: center;
	}
}

@media screen and (max-width: 991px) {
	.category-link {
		width: calc(20% - 5px);
		margin-right: 5%;
	}

	.img-link-row {
		display: block;
	}

	.generic-img {
		margin-left: 20px;
		margin-right: 20px;
		width: calc(100% - 40px);
	}

	.section-wrapper.vertical.grey-bg.cb-product-ribbon {
		text-align: left;
	}
}

@media screen and (max-width: 767px) {
	.img-link-row {
		top: 30px;
		margin-bottom: 30px;
	}

	.category-link .category-img {
		display: block;
		margin-right: 20px;
		margin-left: 20px;
		width: calc(100% - 40px);
		padding-top: 45%;
		padding-bottom: 45%;
	}

	.category-link {
		width: auto;
		margin: 0px;
	}

	._6pcs,
	.categories-grid {
		display: block;
	}

	._6pcs {
		width: 89%;
		margin-left: auto;
		margin-right: auto;
	}

	.img-link-row._1,
	.img-link-row._2 {
		width: 89%;
	}

	.product-spotlight-slide {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.section-wrapper.vertical.grey-bg.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-prev,
	.section-wrapper.vertical.grey-bg.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next {
		top: -103px;
	}

	.section-wrapper.vertical.grey-bg.cb-product-ribbon .titles-headings-copy {
		margin-left: 7px;
		width: 70%;
		text-align: left;
	}

	.section-wrapper.vertical.grey-bg.cb-product-ribbon .ribbon-title-container {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 479px) {
	.section-wrapper.vertical.grey-bg.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-prev,
	.section-wrapper.vertical.grey-bg.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next {
		top: -175px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
}

.slider-text p:last-child {
	margin-bottom: 20px;
}

.slider-text h2 {
	color: #fff;
	margin-top: 0;
	font-family: Roboto Condensed, sans-serif;
	font-size: 60px;
	line-height: 62px;
	margin-bottom: 20px;
}

.hero-paragraph {
	width: 80%;
}

@media screen and (max-width: 767px) {
	.slider-text h2 {
		font-size: 40px;
		line-height: 40px;
	}

	.hero-paragraph {
		width: 100%;
		text-align: center;
	}

	.hero-paragraph p {
		text-align: center;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.page-container {
	overflow: hidden;
	padding-top: 101px;
}

.products-container-box .content-container.full {
	width: 100%;
	max-width: 100%;
}

.products-container-box .inside-page-container.white-background {
	background-color: transparent;
}

.products-container-box .inside-page-content.left---right {
	padding-top: 0px;
}

.breadcrumbs-list-2 li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list-2 li:last-child a,
.breadcrumbs-list-2 li:last-child a:hover {
	text-decoration: none;
	color: #00244b;
	font-weight: 700;
}

.left-nav-list-link-nested.active {
	color: #00244b;
	text-decoration: underline;
}

.left-nav-list-link.active {
	background-color: var(--8);
}

.no-ecommerce.inside-page-header-container.no-columns {
	width: 800px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 991px) {
	.leftnav-holder.facets-left-col {
		display: block;
		width: 100%;
	}

	.leftnav-holder.facets-left-col .qt-accordion-item-content-wrap {
		min-height: auto;
	}
}

/*************** Right/Left Sidebar ***************/
/*************************************************/
.left-sidebar {
	color: var(--slate-grey);
	border-radius: 5px;
	padding: 1rem 1rem 0.1rem;
	font-size: 16px;
}

.left-sidebar .box,
.rightnav-holder .box {
	color: var(--slate-grey);
	border-top: 1px rgba(0, 0, 0, 0.15);
	margin-bottom: 2.441em;
	margin-left: 0;
	line-height: 1.5em;
}

.rightnav-holder td img {
	max-width: none;
}

.rightnav-holder .box h4,
.rightnav-holder .box h3 {
	text-transform: none;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-bottom: 0.75rem;
	padding-left: 0;
	line-height: 1.775rem;
	font-size: 18px;
	font-weight: 700;
}

.rightnav-holder .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.rightnav-holder .box .contentbox_item_image {
	width: 24px;
}

.rightnav-holder .box.documents td:first-child {
	padding-right: 7px;
}

.rightnav-holder .box.tools td:first-child img {
	margin-right: 5px;
}

.rightnav-holder .box a,
.rightnav-holder .box a:hover,
.left-sidebar .box a,
.left-sidebar .box a:hover {
	border-style: none none solid;
	border-width: 0 0 2px;
	border-color: transparent transparent var(--orange);
	color: var(--slate-grey);
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
	transition: all 0.2s;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.content-container.full a,
.content-container.full a:hover {
	color: #333;
	text-decoration: underline;
	transition: all 0.2s;
}

.content-container.full a:hover {
	color: var(--orange);
	text-decoration: none;
}

h1 {
	color: var(--slate-grey);
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	line-height: 42px;
}

h3 {
	font-size: 28px;
	margin-top: 0px;
}

h4 {
	font-size: 24px;
}

h5 {
	text-transform: none;
	font-size: 18px;
}

h6 {
	text-transform: none;
	font-size: 16px;
}

p {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

li {
	margin-bottom: 0;
	font-size: 1rem;
	line-height: 1.5rem;
}

@media screen and (max-width: 991px) {
	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 26px;
	}

	h4 {
		font-size: 22px;
	}
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 30px;
	}

	p {
		text-align: left;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 25px;
		line-height: 27px;
	}
}

@media screen and (max-width: 991px) {
	h2 a {
		font-size: 30px;
	}

	h3 a {
		font-size: 26px;
	}

	h4 a {
		font-size: 22px;
	}
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	padding: 9px 15px;
	color: white !important;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	text-transform: capitalize;
	text-shadow: none;
	background-color: #ff8000 !important;
	border-radius: 5px;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	border-bottom: 0px !important;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #1c2b3c !important;
	transform: translate(0, -3px);
	border-bottom: 0px !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	padding: 9px 15px;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	border: 1px none var(--slate-grey);
	outline-color: var(--slate-grey);
	outline-offset: -1px;
	color: var(--slate-grey) !important;
	text-transform: capitalize;
	background-color: rgba(0, 0, 0, 0) !important;
	border-radius: 5px;
	outline-width: 1px;
	outline-style: solid;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	border-bottom: 0px !important;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	color: #fff !important;
	background-color: #1c2b3c !important;
	transform: translate(0, -3px);
	border-bottom: 0px !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 9px 15px;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	border: 1px none var(--slate-grey);
	outline-color: var(--slate-grey);
	outline-offset: -1px;
	color: var(--slate-grey) !important;
	text-transform: capitalize;
	background-color: #fff !important;
	border-radius: 5px;
	outline-width: 1px;
	outline-style: solid;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	border-bottom: 0px !important;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: var(--slate-grey) !important;
	background-color: #f6f7f7 !important;
	transform: translate(0, -3px);
	border-bottom: 0px !important;
}

.ui-form-buttons .form_button.primary:hover {
	transform: none;
}

/* messages */
#message.success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

#message.error {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-size: 1.125rem;
}

#message.error p {
	color: #000;
	font-size: 1.125rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #1c2b3c;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
	font-size: 16px;
}

table.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #ff8000;
	border-bottom: 0px solid white;
	color: #1c2b3c;
	text-align: left;
	font-size: 16px;
}

table.styled th a,
table.styled th a:visited {
	color: inherit !important;
	font-weight: inherit;
	text-decoration: underline !important;
	border-bottom: 0px !important;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.9375em;
	line-height: 1.4125em;
	color: #1c2b3c;
}

table.styled.striped tr:nth-child(even) {
	background: #eee;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-color: var(--8);
	color: var(--slate-grey);
	text-align: center;
	border-left-style: none;
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 30px;
	line-height: 1.75rem;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

/*pagination*/
.pagination-wrapper,
.pagination-wrapper > * {
	font-size: 0.9rem;
	line-height: 1.25em;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	border-bottom: 1px solid transparent;
	font-family: Roboto, sans-serif;
	color: #333;
	font-size: 1rem;
	font-style: normal;
}

.fly-out-menu-container {
	background-color: transparent;
}

.fly-out-menu-container .fly-out-menu-button {
	border: 1px solid #fff;
	border-radius: 15px;
	padding: 7px 10px 5px 10px;
}

.pagination-wrapper.pagination-wrapper-top {
	margin-top: -45px;
}

@media screen and (max-width: 991px) {
	.pagination-wrapper.pagination-wrapper-top {
		margin-top: 0px;
		margin-bottom: 10px;
	}
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

.phone.mobile {
	display: none;
}

.phone.desktop {
	display: inline-block;
}

.footer-list,
.footer-bottom-list {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	display: inline-block;
}

.footer-list li {
	margin: 0px;
	padding: 0px;
	display: block;
}

.footer-bottom-list li {
	margin: 0px;
	padding: 0px;
	display: inline-block;
}

.footer-bottom-list li {
	margin-right: 10px;
}

.footer-bottom-list li:last-child {
	margin-right: 0px;
}

@media (max-width: 991px) {
	.phone.desktop {
		display: none;
	}

	.phone.mobile {
		display: inline-block;
	}

	#GRIPFooterLogo {
		margin-bottom: 1rem;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}

	.footer-list li {
		text-align: center;
	}

	.uui-footer05_link {
		display: inline-block;
	}
}

/*********************** Modules ********************/
/*****************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
	background-color: #1c2b3c;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-fosrm-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/* datepicker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

/* gdpr */
#cookie-consent-form-container {
	z-index: 10000000;
}

#cookie-consent-container {
	z-index: 999999;
	padding: 1rem 1rem;
	background-color: #1c2b3c;
	display: flex;
	justify-content: center;
	box-shadow: 0 -5px #1d1d1b;
}

#cookie-consent-container .consent-actions {
	min-width: 490px;
	order: 2;
}

#cookie-consent-container .consent-disclaimer {
	width: calc(100% - 490px);
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 0.8rem;
	line-height: 1.3rem;
	max-width: 800px;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	flex-direction: row;
}

#cookie-consent-container .primary {
	background-color: var(--orange);
	color: #fff;
	text-align: center;
	border-radius: 10px;
	margin-right: 20px;
	padding: 7px 10px !important;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700 !important;
	text-decoration: none;
}

#cookie-consent-container .primary:hover {
	background-color: var(--orange) !important;
	color: #fff !important;
	transform: none;
	text-decoration: underline;
}

#cookie-consent-container .consent-actions a {
	color: #fff;
	text-align: center;
	border-bottom: 0px;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
}

#cookie-consent-container .consent-actions a:hover {
	text-decoration: underline;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
}

#cookie-consent-form-container form .consent-header {
	background: rgb(250, 250, 255);
	background: linear-gradient(180deg, #fff, #fff 87.5%, #183241 87.5%);
}

#cookie-consent-form-container form .consent-header img {
	max-height: 120px;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	align-items: center;
}

.consent-form-visible .header-container {
	display: none;
}

#cookie-consent-container.minimized {
	border: 1px solid #fff;
	box-shadow: none;
}

#cookie-consent-container.minimized .consent-banner-open-trigger a img {
	transition: transform 250ms ease;
}

#cookie-consent-container.minimized .consent-banner-open-trigger a:hover img {
	transform: rotate(35deg) scale(1.1);
}

@media (max-width: 991px) {
	#cookie-consent-container {
		display: flex;
		flex-direction: column;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100%;
		max-width: 100%;
	}

	#cookie-consent-container .consent-actions a.consent-dismiss-trigger {
		position: absolute;
		top: 10px;
		right: 0;
	}

	#cookie-consent-container .consent-actions {
		min-width: 100%;
	}

	#cookie-consent-container .consent-actions a,
	#cookie-consent-container .primary {
		margin-top: 0px;
	}
}

@media (max-width: 479px) {
	#cookie-consent-container .consent-disclaimer,
	#cookie-consent-container .consent-actions a.consent-all-trigger,
	#cookie-consent-container .consent-actions a.reject-all-trigger,
	#cookie-consent-container .consent-actions a,
	#cookie-consent-container .primary {
		font-size: 0.7rem;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */
.rightnav-holder .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

#moduleDocuments_module.cms_list h3 a {
	font-size: 24px;
}

/* submission form */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/******************* Content Boxes *******************/
/****************************************************/
/*news */
#news_module.homepage_contentbox a {
	text-decoration: none;
}

/* events */
#events_module.homepage_contentbox {
	text-align: left;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
}

/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/******************* eCommerce *******************/
/****************************************************/
.products-container-box {
	background-color: var(--4);
}

/* Categories Lvl-0 */
#products_module.responsive.cms_list .category-wrapper.items.items-categories {
	margin: 0px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}

#products_module.responsive.cms_list .category-wrapper.items.items-categories .category-div-wrapper.item {
	background-color: #fff;
	width: calc(25% - 15px);
	margin-right: 15px;
	margin-bottom: 15px;
	padding: 16px;
	transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
	position: relative;
	border-radius: 5px;
}

#products_module.responsive.cms_list .category-wrapper.items.items-categories .category-div-wrapper.item:hover {
	transform: translate(0, -5px);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

#products_module.responsive.cms_list .category-wrapper.items.items-categories .category-div-wrapper.item a {
	display: flex;
	border-bottom: 0px;
	align-items: center;
	text-decoration: none;
	color: #333;
}

#products_module.responsive.cms_list .category-wrapper.items.items-categories .category-div-wrapper.item a .category-title.level-1 {
	margin-left: 15px;
	width: calc(100% - 95px);
	text-align: left;
	overflow-wrap: break-word;
}

@media (max-width: 991px) {
	#products_module.responsive.cms_list .category-wrapper.items.items-categories .category-div-wrapper.item {
		width: calc(33.3% - 15px);
		margin-right: 15px;
		margin-bottom: 15px;
	}
}

@media (max-width: 767px) {
	#products_module.responsive.cms_list .category-wrapper.items.items-categories .category-div-wrapper.item {
		width: 100%;
		margin-right: 0;
		margin-bottom: 15px;
	}
}

/* Categories Lvl-1 */
.leftnav-holder.facets-left-col + .content-container #products_module.responsive.cms_list .product-cat-lvl1-container .category-card {
	width: 100%;
}

.cat-2-title-img-wraper h4 a,
.cat-2-title-img-wraper h4 a:hover {
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
	border-bottom: 0px !important;
	font-weight: bold;
}

.lvl3-cat-name-wrapper a.cat-title-box {
	border-bottom: 1px solid var(--8);
	padding-top: 10px;
	color: var(--slate-grey);
	font-size: 16px;
	padding-bottom: 10px;
}

.lvl3-cat-name-wrapper a.cat-title-box:hover {
	text-decoration: underline !important;
	border-bottom: 1px solid var(--8);
	color: #333;
}

.lvl3-cat-name-wrapper.hidden {
	display: none;
}

.lvl3-cat-name-wrapper.active {
	display: flex !important;
}

.cat-more.see-more-button .w-icon-dropdown-toggle {
	transition: all 500ms ease;
}

.cat-more.see-more-button.active .w-icon-dropdown-toggle {
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-180deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
}

/* Search facets */
.leftnav-holder.facets-left-col {
	margin-top: 40px;
}

.search-facets-container .collapse-facets-wrapper {
	background-color: #1c2b3c;
	color: #fff;
	text-transform: capitalize;
	font-size: 16px;
	padding: 10px;
	font-weight: 700;
}

.search-facets-container .collapse-facets-wrapper a {
	color: #fff;
}

.search-facets-container .search-filter-title-2 {
	display: none;
}

.search-facets-container .search-facets .search-facet {
	padding: 10px 0px;
	border-bottom: 1px solid #e3e5e7;
}

.search-facets-container .search-facets #search-facets-form .search-facet:last-of-type {
	border-bottom: 0px;
}

.search-facets-container .label-section-title-2,
.search-facets-container .collapsed .label-section-title-2 {
	display: flex;
	justify-content: space-between;
	background-image: none;
	align-items: center;
	font-weight: 500;
	color: #000;
}

.search-facets-container .label-section-title-2:after {
	font-family: 'webflow-icons' !important;
	display: inline-block;
	content: "\e603";
	font-size: 16px;
	height: 16px;
	width: 16px;
	transition: transform 150ms ease;
}

.search-facets-container .collapsed .label-section-title-2:after {
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
}

.search-facets-container .search-face-type-price .search-facet-options-list .search-facet-options-list-items {
	padding-left: 10px;
	padding-right: 10px;
}

.search-facets-container .search-facets .search-facet .price-range-button {
	display: flex;
	justify-content: flex-end;
	padding-right: 3.5%;
}

.search-facets-container .search-facets .search-facet .price-range-button a {
	text-transform: capitalize;
	text-shadow: none;
	background-color: #ff8000;
	border-radius: 5px;
	padding-top: 7px;
	padding-bottom: 7px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	line-height: 19px;
	height: auto;
}

.search-facets-container .search-facets .search-facet .price-range-button a:hover {
	background-color: #1c2b3c;
	transform: translate(0, -3px);
}

.search-facets-container .search-facets .search-facet .price-range {
	border-radius: 5px;
	line-height: 14px;
	margin-bottom: 0px;
	width: calc(50% - 4% - 9px);
	margin-right: 1%;
}

.search-facets-container .search-facets .search-facet .price-range-symbols {
	height: auto;
}

.search-facets .search-facet .price-range-symbols {
	margin-right: 1%;
}

.search-facets .clear-facet-link {
	color: #000;
	text-decoration: underline;
	font-weight: 500;
	padding-left: 10px;
	margin-bottom: 5px;
}

.search-facets-container .selected-facet-option {
	display: inline-block !important;
	padding: 4px 7px;
	margin-right: 6px;
	margin-bottom: 8px;
	background-color: #e9e9e9;
	box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
	font-weight: 700;
	color: #000;
}

.search-facets-container .selected-facet-option a {
	text-decoration: none;
	background-color: #fff;
	padding: 7px 8px;
	margin-right: 4px;
	border-radius: 70%;
	line-height: 10px;
	color: #000;
	display: inline-block;
}

.search-facets-container .search-facet-options-list {
	padding: 0.5rem 1rem;
}

.search-facets-container .search-facet .checkbox-label {
	font-size: 1rem;
}

.search-facets-container .filter-products-link {
	margin: 7px 0px;
	text-transform: capitalize;
	text-shadow: none;
	background-color: #ff8000;
	border-radius: 5px;
	padding: 7px 7px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	line-height: 19px;
	color: #fff;
	display: inline-block;
}

.search-facets-container .filter-products-link:hover {
	background-color: #1c2b3c;
	transform: translate(0, -3px);
}

.search-facets-container .qt-accordion-item-content-wrap {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.search-facets .search-facet .checkbox-label {
	margin-top: 0px;
	line-height: 20px;
}

.search-facets .search-facet .filter-checkbox {
	margin: 4px 0px;
	display: flex;
	height: auto;
	float: left;
	width: 100%;
}

.search-facets-container .search-facets .search-facet .filter-checkbox-box {
	margin: 2px 0 0 0;
}

.search-module.search-form-top p {
	display: flex;
	padding: 1rem 1rem 0px 1rem;
}

.search-show-filter-container {
	padding: 0rem 1rem;
}

.search-module.search-form-top p button {
	display: flex;
}

.search-module.search-form-top p input[type=search] {
	margin-bottom: 0px;
	margin-right: 10px;
	display: flex;
	width: 100%;
}

@media (max-width: 991px) {
	.leftnav-holder.facets-left-col {
		margin-top: 0;
	}
}

/* Product List Tile */
.property-slider-widget-2.w-slider {
	height: auto;
	background-color: transparent;
	overflow: visible;
}

.property-slider-widget-2.w-slider .w-slider-mask {
	overflow: visible;
}

.property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-prev {
	position: absolute;
	top: -30px;
	right: 70px;
	border-radius: 8px;
	width: 45px;
	height: 45px;
	background-color: #fff;
	display: flex;
	color: #77808a;
}

.property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next {
	position: absolute;
	top: -30px;
	right: 0;
	border-radius: 8px;
	width: 45px;
	height: 45px;
	background-color: #fff;
	display: flex;
	color: #77808a;
}

.property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-prev .icon-5,
.property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next .icon-5 {
	font-size: 1.3rem;
	font-weight: normal;
}

.property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-prev:hover,
.property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next:hover {
	background-color: var(--slate-grey);
	color: #fff;
}

.property-slider-widget-2.w-slider .property-spotlight-mask-2.w-slider-mask .owl-stage-outer {
	padding: 10px 0px;
}

.property-slider-widget-2.w-slider .property-spotlight-mask-2.w-slider-mask .owl-stage {
	display: flex;
}

.property-slider-widget-2.w-slider .property-spotlight-mask-2.w-slider-mask .owl-item {
	margin-bottom: 15px;
	margin-top: 15px;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 {
	background-color: #fff;
	padding: 15px;
	width: calc(100% - 12px);
	margin: 6px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2:hover,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2:hover {
	overflow: hidden;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .product-tile-wishlist,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .product-tile-wishlist {
	text-align: right;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .wishlist-save-icon-2,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .wishlist-save-icon-2 {
	padding: 0px;
	background-color: transparent !important;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .product-tile-img,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .product-tile-img {
	overflow: hidden;
	height: 139px;
	display: flex;
	align-items: center;
	margin: 0 auto;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .product-tile-img a,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .product-tile-img a {
	display: inline-block;
	border-bottom: 0px;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .product-tile-img img,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .product-tile-img img {
	width: auto;
	height: 150px;
	max-width: 90%;
	max-height: 100%;
	max-height: 210px;
	object-fit: scale-down;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .prop-spotlight-details-wrapper-3,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .prop-spotlight-details-wrapper-3 {
	padding-left: 0px;
	padding-right: 0px;
	display: flex;
	height: 100%;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 a.product-name,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 a.product-name {
	border-bottom: 0px;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .spotlight-list-wrapper-3,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .spotlight-list-wrapper-3 {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 a.product-name:hover,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 a.product-name:hover {
	color: var(--orange);
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .in-stock-product-code-div,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .in-stock-product-code-div {
	justify-content: flex-start;
	flex-grow: 1;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .delivery-wrap,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .delivery-wrap {
	margin-top: 7px;
	margin-bottom: 17px;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .labels,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .labels {
	height: fit-content;
	margin-bottom: 7px;
	flex-wrap: nowrap;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .large-now-price.centered,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .large-now-price.centered {
	display: flex;
	flex-direction: column;
	margin-top: 15px;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .listing-details-heading .with-sale,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .listing-details-heading .with-sale {
	order: 2;
	color: var(--orange);
	font-size: 1.4rem;
	font-weight: 900;
	line-height: 1.75rem;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .listing-details-heading .large-was-price,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .listing-details-heading .large-was-price {
	color: rgba(28, 43, 60, 0.64);
	font-size: 16px;
	font-weight: 400;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .spotlight-unordered-list,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .spotlight-unordered-list {
	margin-bottom: 5px;
}

.property-slider-widget-2.w-slider .property-spotlight-slide-2 .add_to_cart_form,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .property-spotlight-slide-2 .add_to_cart_form {
	flex-grow: 1;
}

/* Prodcut List View */
#products_module.responsive.cms_list {
	margin-top: 36px;
}

#products_module.responsive.cms_list .items.grid-view {
	margin: 10px 0px 30px 0px;
	display: flex;
	flex-wrap: wrap;
	width: calc(100% + 0.6%);
	margin-left: -0.3%;
}

#products_module.responsive.cms_list .items.grid-view .item-wrapper {
	margin-bottom: 20px;
	padding-left: 0.3%;
	padding-right: 0.3%;
}

#products_module.responsive.cms_list .items.grid-view .item-wrapper a {
	text-decoration: none;
}

#products_module.responsive .category-heading {
	margin: 0px;
}

/* Prodcut Details View */
#products_module.responsive .add_to_cart_form .submit_row_center {
	display: flex;
}

.submit_row.submit_row_center #OutOfStockContainer {
	display: flex;
	align-items: center;
	margin-right: 20px;
}

.submit_row.submit_row_center #OutOfStockContainer .add-to-cart-icon-4 {
	width: auto !important;
}

#products_module.responsive .add_to_cart_form .quantity_row,
#products_module.responsive .add_to_cart_form .contentbox_item {
	color: #1c2b3c;
}

#products_module.cms_entity .ribbon-title {
	color: var(--slate-grey);
	text-transform: capitalize;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 38px;
	line-height: 40px;
	display: inline-block;
	font-weight: 500;
}

#products_module.cms_entity .delivery-message-region {
	display: inline-block;
	width: 100%;
	padding: 0px 32px 20px 32px;
	background-color: #fff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

#products_module.responsive .add_to_cart_form .submit_row_center {
	margin-top: 10px;
	padding: 10px 32px 0px 32px;
	background-color: #fff;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

#products_module.responsive .product-description-div {
	padding: 32px 32px 0px 32px;
	background-color: #fff;
	display: inline-block;
	width: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

#products_module.responsive .add_to_cart_form_content {
	display: inline-block;
	width: 100%;
	padding: 0px 32px 25px 32px;
	background-color: #fff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

#products_module.responsive .item-prices {
	display: inline-block;
	width: 100%;
	padding: 0px 32px 0px 32px;
	background-color: #fff;
}

#products_module.cms_entity .delivery-message-region .div-block-124 {
	margin: 20px 0px;
}

#products_module.responsive.cms_entity .product-details-tabs {
	float: none;
	width: 65%;
	margin: 40px 17%;
	display: inline-block;
	background-color: #fff;
	display: inline-block;
	padding: 32px 32px 22px 32px;
	border-radius: 5px;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content {
	padding: 0;
	border: 0px;
	border-radius: 0px;
	padding-top: 15px;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item {
	border: 0px;
	font-weight: 600;
	text-transform: none;
	color: #333;
	background-color: transparent;
	font-size: 28px;
	padding: 0;
	border-radius: 0px;
	line-height: 30px;
	cursor: default;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item:hover {
	cursor: default;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item:hover {
	background-color: transparent;
}

#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item:hover,
#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item {
	background-color: #ffffff;
}

#products_module.responsive.cms_entity .mini-slide-image-inner-wrapper {
	height: 90px;
	line-height: 90px;
}

#products_module.responsive.cms_entity .mini-slide-image-outer-wrapper {
	border: 0;
	background-color: #fff;
	border-radius: 5px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper {
	width: 35%;
	float: right;
	margin-left: auto;
	margin-right: 5vw;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
	width: 55%;
	align-content: center;
	background: white;
}

.product-details-slideshow-wrapper .no-image {
	background: white;
	width: 100%;
}

.product-details-slideshow-wrapper .no-image .no-image-container img {
	width: 64%;
}

#products_module.responsive.cms_entity .product-code-product-details {
	color: #1c2b3c;
	font-size: 16px;
	font-weight: 300;
	line-height: 0.8rem;
	display: inline-block;
	width: 100%;
	margin-bottom: 0px;
}

#products_module.responsive.cms_entity .product-details-wrapper.no-carousel {
	display: flex;
}

.slick-track {
	display: flex !important;
	align-items: center;
}

#products_module.responsive.cms_entity .product-title {
	margin-bottom: 12px;
}

#products_module.responsive.cms_entity .product-details-desc-text {
	margin-top: 20px;
}

#products_module.responsive.cms_entity .listing-details-column.large-now-price {
	color: var(--orange);
	font-size: 2.5rem;
	font-weight: 900;
	line-height: 2.5rem;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

#products_module.responsive.cms_entity .listing-details.large-was-price {
	font-size: 1.5rem;
	color: rgba(28, 43, 60, 0.64);
	font-weight: 400;
	line-height: 2rem;
}

#products_module.responsive.cms_entity .listing-details-heading.large-now-price.with-sale {
	order: 2;
}

#products_module.responsive.cms_entity .list-view-cart-row.add-to-cart-button.cart-icon-link-wrapper-3 {
	display: flex;
	margin-top: 17px;
	align-items: center;
}

#products_module.responsive.cms_entity .add-to-cart-button .quantity-wrapper {
	display: inline-flex;
	border: 1px solid #babfc4;
	border-radius: 7px;
	justify-content: center;
	align-items: center;
	padding: 3px;
	margin-right: 12px;
}

#products_module.responsive.cms_entity .wishlist-save-icon-2 {
	padding: 0px;
	margin: 0px;
	margin-right: 12px;
	background-color: transparent !important;
	width: 40px;
	height: 40px;
	max-width: 40px;
	max-height: 40px;
	background-size: 25px;
}

#products_module.responsive .add_to_cart_form .attributeSelection {
	border-radius: 7px;
}

#products_module.responsive.cms_entity .add-to-cart-icon-4 {
	max-width: 160px;
	width: 100%;
}

#products_module.responsive.cms_entity .add-to-cart-button .quantity-wrapper .quantity-minus-button-wrapper a,
#products_module.responsive.cms_entity .add-to-cart-button .quantity-wrapper .quantity-plus-button-wrapper a {
	border-bottom: 0px;
	color: #1c2b3c;
	font-size: 1.3rem;
	width: 24px;
	line-height: 1.5rem;
	text-decoration: none;
}

#products_module.responsive.cms_entity .add-to-cart-button .quantity-wrapper .quantity-text-input {
	width: 34px;
	margin-bottom: 0px;
	font-size: 1.2rem;
	border: 0px;
	font-weight: 500;
	padding: 0px 0px;
	text-align: center;
}

#products_module.responsive.cms_entity .add-to-cart-button .quantity-wrapper .quantity-plus-button-wrapper a:hover {
	transform: scale(1.2);
}

#products_module.responsive.cms_entity .add-to-cart-button .quantity-wrapper .quantity-minus-button-wrapper a:hover {
	transform: scale(1.3);
}

#products_module.responsive.cms_entity .add-to-cart-icon-4 {
	margin: 0px;
}

#products_module.responsive.cms_entity .labels.space {
	display: flex;
}

#products_module.responsive.cms_entity .product-title-container {
	display: flex;
	width: 100%;
}

#products_module.responsive.cms_entity .product-title-container .product-title {
	display: inline-block;
	flex-grow: 1;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

#products_module.responsive.cms_entity .product-title-container .sharethis-container {
	float: right;
	min-width: 40px;
	margin-left: 10px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper.no-images {
	margin-right: 0;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .js-larger-image {
	background-color: #fff;
	border-radius: 5px;
}

@media (max-width: 991px) {
	#products_module.responsive.cms_entity .product-details-wrapper.no-carousel {
		display: block;
	}

	#products_module.responsive.cms_entity {
		margin-top: -20px;
	}

	#products_module.responsive.cms_entity .product-details-tabs {
		width: 100%;
		margin-left: 0px;
		margin-top: 20px;
		margin-right: 0px;
	}

	#products_module.responsive.cms_entity .product-details-info-wrapper {
		width: 100%;
		padding-top: 30px;
		margin: 0px 0px 0px 0px;
		border-radius: 4px;
	}

	#products_module.responsive.cms_entity .product-title {
		width: 100%;
		margin: 0px;
		background-color: transparent;
		padding: 0px 32px 10px 0px;
	}

	#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
		width: 100%;
		margin: 0px;
	}
}

/* Product Ribbons */
.cb-product-ribbon .ribbon-title-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

.cb-product-ribbon .ribbon-title-text {
	margin-left: 3px;
	margin-right: 3px;
	font-size: 16px;
	font-weight: 500;
	display: inline-block;
	text-decoration: none;
	padding: 9px 30px;
	text-align: left;
	cursor: pointer;
}

.cb-product-ribbon .ribbon-title-text.active {
	background-color: var(--slate-grey);
	color: #fff;
	border-radius: 5px;
}

.cb-product-ribbon .product-ribbons-container {
	display: none;
	max-width: 1550px;
	margin: 0 auto;
}

.cb-product-ribbon .product-ribbons-container.active {
	display: block;
}

.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-prev,
.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next {
	top: -42px;
}

@media (max-width: 991px) {
	.cb-product-ribbon .ribbon-title-container {
		justify-content: flex-start;
	}

	.cb-product-ribbon .ribbon-title-text {
		background-color: #fff;
		margin-right: 6px;
	}
}

@media (max-width: 767px) {
	.cb-product-ribbon .ribbon-title-container {
		margin-bottom: 50px;
	}

	.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-prev,
	.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next {
		top: -30px;
	}

	.cb-product-ribbon .property-slider-widget-2.w-slider .property-slider-arrow-link-4.owl-next {
		margin-right: 7px;
	}

	.cb-product-ribbon .ribbon-title-text {
		font-size: 14px;
		padding: 9px 10px;
		text-align: center;
	}
}

@media (max-width: 479px) {
	.cb-product-ribbon .ribbon-title-container {
		width: 100%;
		flex-wrap: wrap;
	}

	.cb-product-ribbon .ribbon-title-text {
		margin-bottom: 10px;
		width: calc( 50% - 14px);
		border-radius: 5px;
		margin-left: 7px;
		margin-right: 7px;
	}
}

/* Product Category Slider */
.carousel-wrapper.product-cat {
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
	width: 100vw;
}

.lvl-3-cat-name-carousel-wrap .heading-19 {
	text-align: center;
}

#product-cat-slider {
	width: 90%;
	margin: 0 auto;
	text-align: center;
}

#product-cat-slider .lvl3-cat-img-circle {
	margin-left: auto;
	margin-right: auto;
}

#product-cat-slider .heading-18 {
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	line-height: 20px;
	margin-top: 20px;
	margin-bottom: 10px;
	color: #1c2b3c;
	display: inline-block;
}

.product-slider-arrow.lvl2slider {
	position: absolute;
	z-index: 100000;
}

.product-slider-arrow.lvl2slider.left {
	left: -2rem;
	right: auto;
}

.product-slider-arrow.lvl2slider.right {
	left: auto;
	right: -2rem;
}

.delivery-method-wrapper .ui-form-radio {
	display: flex;
	align-items: start;
}

.delivery-method-wrapper .ui-form-radio input {
	margin-top: 3px;
}

@media (min-width: 992px) {
	#product-cat-slider.no-slider .slick-track {
		transform: none !important;
	}
}

@media screen and (max-width: 991px) {
	.product-slider-arrow.lvl2slider.left {
		left: -1%;
	}

	.product-slider-arrow.lvl2slider.right {
		right: -1%;
	}

	.carousel-wrapper.product-cat {
		margin-bottom: 0px;
	}
}
