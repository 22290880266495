fieldset {
	border: none;
}

	fieldset h3,
	#s-order-confirmation .ui-data-addresses h3 {
		margin-top: 0;
	}

	fieldset.collapsed {
		background: none;
	}

	.ui-form-panel {
		border: none;
	}

	.ui-form-field {
		width: 100%;
	}

	.ui-form-select > select,
	.ui-form-textarea > textarea,
	.ui-form-text > input[ type="password" ],
	.ui-form-text > input[ type="text" ] {
		width: 100%;
		max-width: 348px;
	}

	.ui-single-column-fieldset .ui-form-fields {
		padding: 0;
		width: 100%;
		overflow: auto;
	}

		.ui-single-column-fieldset .ui-form-field {
			width: 100%;
			height: auto;
			overflow: auto;
		}

	.ui-single-column-fieldset .ui-form-label {
		clear: both;
		padding-right: 0;
		text-align: left;
		width: 100%;
		overflow: auto;
		position: relative;
		left: auto;
		top: auto;
		height: auto;
	}

	.ui-single-column-fieldset .ui-form-input {
		clear: both;
		width: 100%;
		overflow: auto;
		position: relative;
	}


@media ( max-width: 479px ) {

	.ui-form-fields {
		padding: 0;
		width: 100%;
		overflow: auto;
	}

		.ui-form-field {
			width: 100%;
			height: auto;
			overflow: auto;
		}

	.ui-form-label {
		clear: both;
		padding-right: 0;
		text-align: left;
		width: 100%;
		overflow: auto;
		position: relative;
		left: auto;
		top: auto;
		height: auto;
	}

	.ui-form-input {
		clear: both;
		width: 100%;
		overflow: auto;
		position: relative;
	}

	.ui-form-input.ui-form-button {
		overflow: hidden;
	}

	.ui-form-select select,
	.ui-form-text > input[ type="text" ] {
		clear: both;
		width: 100%;
	}

	.ui-form-buttons {
		padding: 0 !important;
		width: 100%;
	}

	.ui-form-buttons button {
		text-align: center;
		width: 100%;
	}

	.ui-form-select > select,
	.ui-form-textarea > textarea,
	.ui-form-text > input {
		max-width: 100%;
	}

}
